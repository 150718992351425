import React, {Fragment} from "react";
import {List, ListItem} from "framework7-react";
import {indexBy, join, pathOr, prop} from "ramda";

export default ({checkout, showNoDiscountsMessage}) => {
  const items = pathOr([], ['shoppingCart', 'items'], checkout);
  const productsById = indexBy(prop('id'), items.map(({product}) => product));

  const toText = references => {
    return join('; ', references.map(({id, offer}) => `${productsById[id].name} - ${offer}`))
  };

  if (pathOr([], ['discounts'], checkout).length > 0) {
    const {discounts} = checkout;
    return (
      <List className="discounts" mediaList noHairlines noHairlinesMd>
        {discounts.map(({name, amount, references}, i) => (
          <ListItem
            key={i} title={name} after={`-$${Number.parseFloat(amount).toFixed(2)}`}
            subtitle="Applied to:" text={toText(references)}/>
        ))}
      </List>
    )
  } else if (showNoDiscountsMessage) {
    return (
      <p>
        We could not find any applicable discounts for your shopping cart.
        Please, proceed to the next step.
      </p>
    )
  } else {
    return (
      <Fragment/>
    )
  }
}

