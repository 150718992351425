import React from 'react';
import {Block, Col, Icon, Link, Row, Tab, Tabs, Toolbar} from "framework7-react";
import mapRoanokeImage from "../images/map-roanoke.png";
import mapPlanoImage from "../images/map-plano.png";

export default ({colorTheme}) => {
  const latitudeRoanoke = 32.997030, longitudeRoanoke = -97.228520;
  const latitudePlano = 33.019976, longitudePlano = -96.750889;
  const geoReferenceRoanoke = `geo:${latitudeRoanoke},${longitudeRoanoke}`;
  const geoReferencePlano = `geo:${latitudePlano},${longitudePlano}`;
  const emailRef = "mailto: info@artwheelstudio.com";
  const phoneRef = "tel: 6827473642";

  return (
    <Block className='locations-and-contact-info'>
      <Row className='well'>
        <Toolbar tabbar>
          <Link tabLink="#tab-roanoke" tabLinkActive>Roanoke</Link>
          <Link tabLink="#tab-plano">Plano</Link>
        </Toolbar>
      </Row>
      <Tabs>
        <Tab id='tab-roanoke' tabActive>
          <Row className="about-map well">
            <Col width="100">
              <Link href={geoReferenceRoanoke} external>
                <img className="about-map" src={mapRoanokeImage} alt="Map"/>
              </Link>
            </Col>
          </Row>
          <Row className='contact_info contact_info__address well'>
            <Link href={geoReferenceRoanoke} external>
              <Icon f7="home" colorTheme={colorTheme}/>
            </Link>
            <Link href={geoReferenceRoanoke} external>300 Bowie Street, Roanoke, TX</Link>
          </Row>
        </Tab>
        <Tab id='tab-plano'>
          <Row className="about-map well">
            <Col width="100">
              <Link href={geoReferencePlano} external>
                <img className="about-map" src={mapPlanoImage} alt="Map"/>
              </Link>
            </Col>
          </Row>
          <Row className='contact_info contact_info__address well'>
            <Link href={geoReferencePlano} external>
              <Icon f7="home" colorTheme={colorTheme}/>
            </Link>
            <Link href={geoReferencePlano} external>2999 W 15<sup>th</sup> Street, Plano, TX</Link>
          </Row>
        </Tab>
      </Tabs>
      <Row className='contact_info contact_info__email well'>
        <Link href={emailRef} external>
          <Icon f7="email" colorTheme={colorTheme}/>
        </Link>
        <Link href={emailRef} external>info@artwheelstudio.com</Link>
      </Row>
      <Row className='contact_info contact_info__phone well'>
        <Link href={phoneRef} external>
          <Icon f7="phone_round" colorTheme={colorTheme}/>
        </Link>
        <Link href={phoneRef} external>(682)747-3642</Link>
      </Row>
    </Block>
  );
}
