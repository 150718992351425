import React, {useState} from 'react';
import {Block, BlockFooter, BlockTitle, Col, Page, PageContent, Row} from 'framework7-react';
import {useDispatch, useSelector} from "react-redux";
import shallowEqual from "react-redux/es/utils/shallowEqual";
import NavigationBar from "../../components/NavigationBar";
import {
  book,
  checkIfExistingCustomerAndCalculateDiscounts,
  getAppliedCredit,
  getConvenienceFee,
  getTotalWithoutConvenienceFee,
  isForAdults
} from "../../actions/checkout";
import CheckoutButtons from "../../components/checkout/CheckoutButtons";
import CustomerInfo from "../../components/checkout/CustomerInfo";
import Coupons from "../../components/checkout/Coupons";
import PaymentPreferences from "../../components/checkout/PaymentPreferences";
import Discounts from "../../components/checkout/Discounts";
import {pathOr} from "ramda";
import {handleReload} from "../../store";
import ReloadPage from "../ReloadPage";
import ApiError from "../../components/checkout/ApiError";
import ApiCall, {shouldMakeApiCall} from "../../components/checkout/ApiCall";
import ProductDetails, {renderHeader} from "../../components/checkout/ProductDetails";

export default ({f7router}) => {
  if (handleReload(useSelector(s => s, shallowEqual), f7router)) {return (<ReloadPage f7router={f7router}/>);}
  const colorTheme = useSelector(state => state.settings.colorTheme, shallowEqual);
  const themeDark = useSelector(state => state.settings.themeDark, shallowEqual);
  const checkout = useSelector(state => state.checkout, shallowEqual);
  const items = pathOr([], ['shoppingCart', 'items'], checkout);
  const settings = useSelector(state => state.settings, shallowEqual);
  const totalWithoutConvenienceFee = getTotalWithoutConvenienceFee(checkout);
  const convenienceFee = getConvenienceFee(checkout);
  const [hasValidCustomerInfo, setHasValidCustomerInfo] = useState(false);
  const appliedCredit = getAppliedCredit(checkout);
  const dispatch = useDispatch();
  const hasRequiredNames = items.every(i => (
    isForAdults(i.product.sku) && i.student.sameAsUser) || i.student.name.length > 0)
  ;
  const canProceed = hasValidCustomerInfo && hasRequiredNames;
  const {app} = f7router;
  const isApiCallEnabled = shouldMakeApiCall(app, checkout, "existingCustomer");

  const bookHandler = () => {
    app.dialog.preloader('Booking ...');
    // noinspection JSValidateTypes
    dispatch(book(checkout, settings))
      .then(() => f7router.navigate('/checkout/confirmation'))
      .finally(() => app.dialog.close());
  };

  const renderTotal = () => {
    const calculatedTotal = totalWithoutConvenienceFee + convenienceFee;
    const effectiveCredit = appliedCredit <= calculatedTotal ? appliedCredit : calculatedTotal;
    const effectiveTotal = calculatedTotal - effectiveCredit;
    const creditNote =  effectiveCredit <= 0 ? '' :
      `($${calculatedTotal.toFixed(2)} less the credit of $${effectiveCredit.toFixed(2)})`;
    return (
      <BlockFooter>
        <h3>Amount due: ${effectiveTotal.toFixed(2)} {creditNote}</h3>
      </BlockFooter>
    );
  }

  return (
    <Page className='desktop-checkout-page' colorTheme={colorTheme} themeDark={themeDark}>
      <NavigationBar title="Checkout" subtitle="Checkout: step 2 of 2" hasBackLink={true}/>
      <ApiError f7router={f7router}/>
      <ApiCall app={app} message="Checking e-mail ..." isEnabled={isApiCallEnabled} action={() => {
        return checkIfExistingCustomerAndCalculateDiscounts(checkout.email, settings, items)
      }}
      />
      <PageContent>
        <Row className='checkout-details'>
          <Col width='25'>
            <Row className='checkout-details-section'>
              <CustomerInfo
                f7router={f7router} showButtons={false}
                validityHandler={isValid => setHasValidCustomerInfo(isValid) }
              />
            </Row>
            <Row className='checkout-details-section'>
              <PaymentPreferences showButtons={false} />
            </Row>
          </Col>
          <Col width='25'>
            <Row className='checkout-details-section'>
              <Coupons f7router={f7router} showButtons={false} />
            </Row>
            <Row className='checkout-details-section'>
              <Block>
                <BlockTitle>Discounts</BlockTitle>
                <Discounts checkout={checkout} showNoDiscountsMessage={true}/>
              </Block>
            </Row>
          </Col>
          <Col width='50'>
            <Row className='checkout-details-section'>
              <Block className='checkout-details-products'>
                <BlockTitle>Please, review the products you are about to book</BlockTitle>
                <p>
                  Are you booking for your children?
                  Please, provide their names below for relevant products.
                </p>
                {renderHeader()}
                {items.map((item, index) => <ProductDetails item={item} index={index}/>)}
              </Block>
            </Row>
          </Col>
        </Row>
        <Row className='checkout-total'>
          <Block>
            {renderTotal()}
            <CheckoutButtons
              f7router={f7router} canProceed={canProceed} continueText="Book"
              continueHandler={bookHandler}/>
          </Block>
        </Row>
      </PageContent>
    </Page>
  );
}