import React, {Fragment} from 'react';
import {Block, BlockFooter, Button, Icon, List, ListItem, Page, PageContent} from 'framework7-react';
import {useDispatch, useSelector} from "react-redux";
import shallowEqual from "react-redux/es/utils/shallowEqual";
import NavigationBar from "../components/NavigationBar";
import ShoppingCartItem from "../components/ShoppingCartItem";
import {startCheckout} from "../actions/checkout";
import {sum} from 'ramda';
import {handleReload} from "../store";
import ReloadPage from "./ReloadPage";

export default ({f7router}) => {
  if (handleReload(useSelector(s => s, shallowEqual), f7router)) {return (<ReloadPage f7router={f7router}/>);}
  const colorTheme = useSelector(state => state.settings.colorTheme, shallowEqual);
  const themeDark = useSelector(state => state.settings.themeDark, shallowEqual);
  const shoppingCart = useSelector(state => state.shoppingCart, shallowEqual);
  const dispatch = useDispatch();
  const {items} = shoppingCart;
  const {app} = f7router;

  const checkoutHandler = () => {
    dispatch(startCheckout(shoppingCart));
    f7router.navigate('/checkout/email/');
  };

  const renderDiscounts = () => {
    return (
      <ListItem
        className="shopping-cart-item" key={items.length + 1} title="Discounts"
        subtitle="Discounts applied during checkout">
        <Icon slot="media" f7="money_dollar_round"/>
      </ListItem>
    );
  };

  const renderTotal = shoppingCart => {
    // noinspection JSUnresolvedVariable
    const itemTotal = sum(shoppingCart.items.map(i => Number.parseFloat(i.product.price)));
    const discountTotal = 0;
    const cartTotal = itemTotal + discountTotal;
    const cartTotalMessage = `Your cart total: $${cartTotal}`;
    return (
      <BlockFooter>
        <h3 className="shopping-cart-total">{cartTotalMessage}</h3>
      </BlockFooter>
    );
  };

  const renderShoppingCart = () => {
    if (items.length > 0) {
      return (
        <Fragment>
          <List mediaList form>
            {items.map((currentItem, index) => (<ShoppingCartItem item={currentItem} index={index} app={app}/>))}
            {renderDiscounts()}
          </List>
          {renderTotal(shoppingCart)}
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <p>Your shopping cart is empty.</p>
          <p>Please, browse our catalog for classes, camps and events.</p>
        </Fragment>
      );
    }
  };

  const renderButton = () => {
    if (items.length > 0) {
      return (<Button raised round onClick={checkoutHandler}>Checkout</Button>);
    } else {
      return (<Button raised round onClick={() => f7router.navigate('/')}>Home</Button>);
    }
  }

  return (
    <Page className='cart' colorTheme={colorTheme} themeDark={themeDark}>
      <NavigationBar title="Shopping Cart" hasBackLink={true}/>
      <PageContent>
        <Block>
          {renderShoppingCart()}
          {renderButton()}
        </Block>
      </PageContent>
    </Page>
  );
}