import React from 'react';
import {useDispatch} from 'react-redux';
import {Button, Col, Row} from 'framework7-react';
import {cancelCheckout} from "../../actions/checkout";


export default ({f7router, canProceed, continueHandler, continueText}) => {
  const dispatch = useDispatch();
  const {app} = f7router;

  const cancelHandler = () => {
    app.dialog.confirm('Are you sure?', 'Cancel the Checkout', () => {
      dispatch(cancelCheckout());
      f7router.clearPreviousHistory();
      f7router.navigate('/');
    });
  };

  return (
    <Row className="checkout-buttons">
      <Col><Button raised round onClick={cancelHandler}>Cancel</Button></Col>
      <Col>
        <Button className="continue-button" raised round disabled={!canProceed} onClick={continueHandler}>
          {continueText ? continueText : 'Continue'}
        </Button>
      </Col>
    </Row>
  );
}