import {ADD_ITEM, COMPLETE_CHECKOUT, DELETE_ITEM} from "../actions/types";
import {not} from 'ramda';

export default (state = [], action) => {
  switch (action.type) {
    case ADD_ITEM:
      const {product, offer} = action;
      const items = [...state.items, {product, offer}];
      return {...state, items};
    case DELETE_ITEM:
      return {...state, items: state.items.filter((item, index) => not(index === action.index))};
    case COMPLETE_CHECKOUT:
      return {items: []};
    default:
      return state;
  }
};