export const INITIAL_LOAD_SUCCESS = '@aws/INITIAL_LOAD_SUCCESS';
export const INITIAL_LOAD_FAILURE = '@aws/INITIAL_LOAD_FAILURE';

export const GET_CAPACITIES_SUCCESS = '@aws/GET_CAPACITIES_SUCCESS';
export const GET_CAPACITIES_FAILURE = '@aws/GET_CAPACITIES_FAILURE';
export const CLEAR_CAPACITIES = '@aws/CLEAR_CAPACITIES';

export const SET_SHOPPING_PROGRAM = '@aws/SET_SHOPPING_PROGRAM';
export const SET_SHOPPING_CATEGORY = '@aws/SET_SHOPPING_CATEGORY';
export const SET_SHOPPING_LOCATION = '@aws/SET_SHOPPING_LOCATION';
export const SET_SHOPPING_TYPE = '@aws/SET_SHOPPING_TYPE';

export const ADD_ITEM = '@aws/ADD_ITEM';
export const DELETE_ITEM = '@aws/DELETE_ITEM';

export const START_CHECKOUT = '@aws/START_CHECKOUT';
export const CANCEL_CHECKOUT = '@aws/CANCEL_CHECKOUT';
export const SET_CONTACT_EMAIL = '@aws/SET_CONTACT_EMAIL';
export const SET_CUSTOMER_INFO = '@aws/SET_CUSTOMER_INFO';
export const CHECK_IF_EXISTING_CUSTOMER_SUCCESS = '@aws/CHECK_IF_EXISTING_CUSTOMER_SUCCESS';
export const CHECK_IF_EXISTING_CUSTOMER_FAILURE = '@aws/CHECK_IF_EXISTING_CUSTOMER_FAILURE';
export const CALCULATE_DISCOUNTS_SUCCESS = '@aws/CALCULATE_DISCOUNTS_SUCCESS';
export const CALCULATE_DISCOUNTS_FAILURE = '@aws/CALCULATE_DISCOUNTS_FAILURE';
export const APPLY_COUPON_SUCCESS = '@aws/APPLY_COUPON_SUCCESS';
export const APPLY_COUPON_FAILURE = '@aws/APPLY_COUPON_FAILURE';
export const CLEAR_COUPON = '@aws/CLEAR_COUPON';
export const SET_STUDENT_NAME = '@aws/SET_STUDENT_NAME';
export const SET_FORM_OF_PAYMENT = '@aws/SET_FORM_OF_PAYMENT';
export const BOOK_SUCCESS = '@aws/BOOK_SUCCESS';
export const BOOK_FAILURE = '@aws/BOOK_FAILURE';
export const COMPLETE_CHECKOUT = '@aws/COMPLETE_CHECKOUT';

export const APPLY_AND_SAVE_SETTINGS = '@aws/APPLY_AND_SAVE_SETTINGS';

export const CLEAR_ERROR = '@aws/CLEAR_ERROR';
