import React from 'react';
import {Page, PageContent} from 'framework7-react';
import {useSelector} from "react-redux";
import shallowEqual from "react-redux/es/utils/shallowEqual";
import NavigationBar from '../components/NavigationBar';
import {handleReload} from "../store";
import ReloadPage from "./ReloadPage";
import LocationsAndContacts from "../components/LocationsAndContacts";

export default ({f7router}) => {
  if (handleReload(useSelector(s => s, shallowEqual), f7router)) {return (<ReloadPage f7router={f7router}/>);}
  const colorTheme = useSelector(state => state.settings.colorTheme, shallowEqual);
  const themeDark = useSelector(state => state.settings.themeDark, shallowEqual);

  return (
    <Page className="about" colorTheme={colorTheme} themeDark={themeDark}>
      <NavigationBar title="About Us" hasBackLink={true}/>
      <PageContent>
        <LocationsAndContacts colorTheme={colorTheme}/>
      </PageContent>
    </Page>
  );
};