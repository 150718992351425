import React from 'react';
import {Icon, Link} from "framework7-react";

export default () => {
  // noinspection HtmlUnknownTarget
  return (
    <Link iconOnly href='/settings/'>
      <Icon f7="settings" tooltip='Settings'/>
    </Link>
  );
}