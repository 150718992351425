import React, {Fragment} from 'react';
import {Block, BlockTitle, Page, PageContent} from 'framework7-react';
import {useSelector} from "react-redux";
import shallowEqual from "react-redux/es/utils/shallowEqual";
import NavigationBar from "../../components/NavigationBar";
import CheckoutButtons from "../../components/checkout/CheckoutButtons";
import ApiError from "../../components/checkout/ApiError";
import ApiCall, {shouldMakeApiCall} from "../../components/checkout/ApiCall";
import Discounts from "../../components/checkout/Discounts";
import {calculateDiscounts} from "../../actions/checkout";
import {has, pathOr} from "ramda";
import {handleReload} from "../../store";
import ReloadPage from "../ReloadPage";

export default ({f7router}) => {
  if (handleReload(useSelector(s => s, shallowEqual), f7router)) {return (<ReloadPage f7router={f7router}/>);}
  const settings = useSelector(state => state.settings, shallowEqual);
  const checkout = useSelector(state => state.checkout, shallowEqual);
  const items = pathOr([], ['shoppingCart', 'items'], checkout);
  const themeDark = useSelector(state => state.settings.themeDark, shallowEqual);
  const colorTheme = useSelector(state => state.settings.colorTheme, shallowEqual);
  const {app} = f7router;
  const isApiCallEnabled = shouldMakeApiCall(app, checkout, "discounts");

  const renderDiscounts = () => {
    if (has('discounts', checkout)) {
      return (
        <Block>
          <BlockTitle>Applying Discounts</BlockTitle>
          <Discounts checkout={checkout} showNoDiscountsMessage={true}/>
          <CheckoutButtons f7router={f7router} canProceed={true} continueText="Students >" continueHandler={
            () => f7router.navigate('/checkout/students')
          }/>
        </Block>
      );
    } else {
      return (
        <Fragment/>
      );
    }

  };

  return (
    <Page className='checkout checkout__discounts' colorTheme={colorTheme} themeDark={themeDark}>
      <NavigationBar title="Discounts" subtitle="Checkout: step 4 of 7" hasBackLink={true}/>
      <ApiError f7router={f7router}/>
      <ApiCall app={app} message="Getting discounts ..." isEnabled={isApiCallEnabled} action={() => {
        return calculateDiscounts(items, settings)
      }}/>
      <PageContent>
        {renderDiscounts()}
      </PageContent>
    </Page>
  );
}