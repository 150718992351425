import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Button, Col, ListInput, Row} from "framework7-react";
import {addItemToShoppingCart} from '../actions/shoppingCart';

export default ({product, offers, capacities, f7router}) => {
  const [offer, setOffer] = useState('empty');
  const dispatch = useDispatch();
  const {app} = f7router;

  const clickHandler = () => {
    const parameters = {
      title: 'Added to Cart',
      text: 'Continue shopping?',
      buttons: [{text: 'Stay'}, {text: 'Home'}, {text: 'Cart'}],
      onClick: (dialog, index) => {
        const links = ['/', '/cart/'];
        if (index) {
          f7router.navigate(links[index - 1]);
        }
      }
    };
    const dialog = app.dialog.create(parameters);
    dispatch(addItemToShoppingCart(product, offer));
    dialog.open(true);
  };

  const hasCapacity = (option) => {
    if (capacities && option in capacities) {
      const option_capacities = capacities[option]
      return option_capacities.booked < option_capacities.available;
    }
    return true;
  }

  return (
    <Row className="offers">
      <Col width='75'>
        <ListInput type="select" defaultValue="empty" onChange={e => setOffer(e.target.value)}>
          <option key='empty' value='empty'>Select a class</option>
          {offers.map((o, i) => (
            <option
              key={i + 100} value={o} disabled={!hasCapacity(o)}
              className='option-list'>{o}
            </option>
          ))}
        </ListInput>
      </Col>
      <Col width='25'>
        <Button raised round disabled={offer === 'empty'} onClick={clickHandler}>Add</Button>
      </Col>
    </Row>
  );
};
