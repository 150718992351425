import React, {Fragment, useRef, useState} from 'react';
import {Block, Button, List, ListInput, Row} from "framework7-react";
import {isValid} from "../utils";
import {pathOr} from "ramda";
import axios from "axios";
import {getLocations} from "../actions/shopping";

export default ({f7router, settings, showHeader=true}) => {
  const [name, setName] = useState('');
  const [hasValidName, setHasValidName] = useState(false);
  const nameElement = useRef(null);
  const [email, setEmail] = useState(pathOr('', ['email'], settings.email));
  const [hasValidEmail, setHasValidEmail] = useState(email);
  const emailElement = useRef(null);
  const [phone, setPhone] = useState('');
  const [hasValidPhone, setHasValidPhone] = useState(phone);
  const phoneElement = useRef(null);
  const locations = ['', ... getLocations().map(({name}) => name)];
  const [location, setLocation] = useState('');
  const [hasValidLocation, setHasValidLocation] = useState(false);
  const [message, setMessage] = useState('');
  const [hasValidMessage, setHasValidMessage] = useState(false);
  const messageElement = useRef(null);
  const canProceed = hasValidName && hasValidEmail && hasValidPhone && hasValidMessage &&hasValidLocation;

  const sendHandler = () => {
    const {app} = f7router;
    const payload = {name, email, phone, location, message};
    const {api: {baseUrl}} = settings;
    app.dialog.preloader('Loading ...');
    axios.post(`${baseUrl}/contact`, payload)
      .then(() => {
        app.dialog.close();
        const successMessage = 'Thank you for contacting us! We have received the message and will reply shortly';
        app.dialog.alert(successMessage, 'Success', () => {
          f7router.navigate('/')
        })
      })
      .catch(() => {
        app.dialog.close();
        const errorMessage = 'We could not send your message. Please, call us at 682-747-3642';
        app.dialog.alert(errorMessage, 'Error', () => {
          f7router.navigate('/')
        })
      })
  };

  const renderHeader = () => {
    return showHeader ? (<h2 className='contact_us__header'>Have a question? Let us know!</h2>) : (<Fragment/>);
  }

  return (
    <Block className='contact_us'>
      {renderHeader()}
      <List noHairlines noHairlinesMd>
        <ListInput
          type="text" label="Name" floatingLabel value={name} placeholder="Name"
          errorMessage="Name is Required!" clearButton required validate ref={nameElement}
          onChange={e => {
            setName(e.target.value);
            setHasValidName(isValid(nameElement))
          }}
          onInputClear={() => {
            setName('');
            setHasValidName(false)
          }}
        />
        <ListInput
          type="email" value={email} placeholder="E-mail" label="E-mail"
          floatingLabel clearButton errorMessage="Please, provide a valid e-mail address"
          ref={emailElement} required validate
          onChange={e => {
            setEmail(e.target.value);
            setHasValidEmail(isValid(emailElement))
          }}
          onInputClear={() => {
            setEmail('');
            setHasValidEmail(false)
          }}
        />
        <ListInput
          type="tel" label="Phone" floatingLabel value={phone} placeholder="Phone"
          errorMessage="Please, use xxx-xxx-xxxx or xxxxxxxxxx format"
          required validate ref={phoneElement} clearButton
          pattern="([0-9]{3}-[0-9]{3}-[0-9]{4})|([0-9]{10})"
          onChange={e => {
            setPhone(e.target.value);
            setHasValidPhone(isValid(phoneElement))
          }}
          onInputClear={() => {
            setPhone('');
            setHasValidPhone(false)
          }}
        />
        <ListInput
          type="select" label="Location" floatingLabel
          errorMessage="Please, choose location"
          onChange={e => {
            setLocation(e.target.value);
            setHasValidLocation(e.target.value !== '')
          }}>
          {locations.map((name) => <option key={name} value={name}>{name}</option>)}
        </ListInput>
        <ListInput
          type="textarea" label="Message" floatingLabel value={message} placeholder="Message"
          required validate ref={messageElement} errorMessage="Please, provide a message"
          onChange={e => {
            setMessage(e.target.value);
            setHasValidMessage(isValid(messageElement))
          }}
        />
      </List>
      <Row className='buttons'>
        <Button raised round disabled={!canProceed} onClick={sendHandler}>Send</Button>
      </Row>
    </Block>
  );
}
