import React, {Fragment, useEffect} from 'react';
import {pathOr} from "ramda";
import {useDispatch, useSelector} from "react-redux";
import shallowEqual from "react-redux/es/utils/shallowEqual";
import {cancelCheckout} from "../../actions/checkout";

export default ({f7router}) => {
  const error = useSelector(state => state.checkout.error, shallowEqual);
  const dispatch = useDispatch();
  const {app} = f7router;

  const getErrorMessage = error => {
    if (error.response) {
      const message = pathOr(error.message, ['data', 'message'], error.response);
      return `${error.response.status}: ${message}`;
    } else {
      return error.message;
    }
  };

  useEffect(() => {
    if (error) {
      // noinspection JSValidateTypes
      dispatch(cancelCheckout());
      app.dialog.alert(getErrorMessage(error), 'Error', () => {
        f7router.clearPreviousHistory();
        f7router.navigate('/');
      });
    }
  });

  return (
    <Fragment/>
  );
}