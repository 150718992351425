import {
  GET_CAPACITIES_FAILURE,
  GET_CAPACITIES_SUCCESS,
  CLEAR_CAPACITIES,
  SET_SHOPPING_CATEGORY,
  SET_SHOPPING_LOCATION,
  SET_SHOPPING_PROGRAM,
  SET_SHOPPING_TYPE
} from './types';
import {find, propEq} from 'ramda';
import axios from "axios";

const getCapacitiesSuccess = payload => ({type: GET_CAPACITIES_SUCCESS, payload});
const getCapacitiesFailure = error => ({type: GET_CAPACITIES_FAILURE, error});

export const getCapacities = (settings) => dispatch => {
  const {api: {baseUrl}} = settings;
  return axios.get(`${baseUrl}/capacities?timestamp=${new Date().getTime()}`)
    .then(payload => dispatch(getCapacitiesSuccess(payload)))
    .catch(error => dispatch(getCapacitiesFailure(error)));
};

export const clearCapacities = () => ({
  type: CLEAR_CAPACITIES
});

export const setProgram = (program, subtitle) => ({
  type: SET_SHOPPING_PROGRAM,
  program,
  subtitle
});

export const setCategory = category => ({
  type: SET_SHOPPING_CATEGORY,
  category
});

export const setLocation = location => ({
  type: SET_SHOPPING_LOCATION,
  location
});

export const setType = name => ({
  type: SET_SHOPPING_TYPE,
  name
});

export const getLocations = () => {
  return [
    {code: 'RO', name: 'Roanoke'},
    {code: 'PL', name: 'Plano'},
    {code: 'OL', name: 'Online'}
  ]
};

export const getLocationName = code => {
  const locations = getLocations();
  const location = find(propEq('code', code))(locations);
  return location ? location.name : location;
};

export const getTypes = () => {
  return [
    'All', 'Group Classes', 'Events', 'Private Lessons', 'Camps', 'Parties', 'Gift Cards'
  ];
};

export const getCategories = () => {
  return [
    {code: 'ABCXYZ', name: 'All Ages'},
    {code: 'AXZ', name: 'Kids'},
    {code: 'BXYZ', name: 'Teenagers'},
    {code: 'CYZ', name: 'Adults'}
  ];
};

export const getCategoryName = code => {
  const categories = getCategories();
  const category = find(propEq('code', code))(categories);
  return category ? category.name : category;
};

export const SEMESTER_CODES = ['S1', 'S2', 'S3', 'S4', 'SW', 'SS'];