import React, {Fragment, useState} from 'react';
import {Block, BlockTitle, Button, Link, Popover, Row, Tab, Tabs, Toolbar} from "framework7-react";
import Filters from "./Filters";

export default ({f7router}) => {
  const [filterOpen, setFilterOpen] = useState(false);

  return (
    <Fragment>
      <Toolbar tabbar tabbar-scrollable>
        <Link tabLink="#tab-program" tabLinkActive>Program</Link>
        <Link tabLink="#tab-structure">Curriculum</Link>
        <Link tabLink="#tab-class-types">Class Types</Link>
        <Link tabLink="#tab-testimonies">Testimonies</Link>
      </Toolbar>
      <Tabs>
        <Tab id='tab-program' tabActive>
          <p>
            Art Wheel Studio classes are based on European masters’ methods of studying and teaching art.
            Our program is diverse, but all classes have one thing in common: the individual.
            Even in a group class setting, our teachers make sure to delve into each and every student’s work and guide them on their own path.
            The individual student is taught to successfully complete each artwork to whatever end they want, at their own pace.</p>
          <p>A core concept at Art Wheel Studio is the ability to draw or paint from nature. We train students to rely on measuring proportions from life,
            whether in plein airs, still lives, figures, or portraits. Classes often cover anatomy, physics applications,
            and mathematical construction to ensure our students can be self-dependent in their future work.</p>
          <p>Many classes focus on a specific medium and help students grow in its relevant skillset: acrylics, watercolors, oil, graphite,
            charcoal, pastels, and pen and ink. Students also learn how to build their subjects step-by-step or construct them from nature,
            no matter what technique or media they use. We specialize in teaching students both perspective and measurement, composition and
            placing on paper, and color and tone with great stress on shading and light.</p>
          <p>We stress the study of watercolors from a young age. This versatile medium is helpful for sketching and brainstorming more complex works,
            and is very applicable in multiple design or art fields.Students can devote themselves to an academic study of fine art
            and work towards a personal goal – and our academic methods help them do so quickly. A number of AWS students have received
            full-tuition scholarships for their portfolios and gone to pursue their art careers at major US universities.</p>
        </Tab>
        <Tab id='tab-structure'>
          <p>We divide programs by age, but many students can change their grouping by demonstrating skill or with instructor permission. </p>
          <p>Toddler – ages 2 to 5: classes focus on developing artistic and musical thinking, observing beauty, discovering color, training fine motor skills,
            concentration, and patience, and feeling shape and form. </p>
          <p>Explore Art – ages 5 to 12: a greater survey of media, techniques, and design. Classes help develop artistic ability, and teach skills
            to break complex objects down and construct them from basic shapes. Over two sessions, fall and spring, we teach children how to draw
            and paint a wide variety of subjects, like animals portraits, drawing in abstract, and others. </p>
          <p>Academy – ages 12 to 18: students are introduced to advanced subjects and scenes. Academy classes feature more in-depth analysis
            and study of media-specific technique and methods. Class types include still life drawing, figure and portraiture, academic drawing,
            and outdoor plein-airs. We emphasize the principles of design, unity and variety, balance, contrast, rhythm, repetition, proportion, scale,
            and figure-ground relationships. Academy students also build portfolios and are encouraged to participate in local, state, national, and international contests.
            Depending on students' needs, we can provide tutoring for AP portfolio in school and classes at college, or help students organize and create their own portfolio
            for homeschooling applications for AP Studio Art.</p>
          <p>Adults – ages 18+: It is never too late to start painting or to continue mastering form and feather.
            Our adult classes offer something for beginners, artists, and those with a living love for art.
            Adult students can stay with one medium the whole class session or sample all or specific media to get a taste for each.
            We do, however, start with basic composition and pencil drawing as a basis for other media. With our unique method,
            adult students tend to master these basics quickly. Instead of simply copying (from photos), students learn how to construct
            the drawing and execute it realistically, whether from reference or nature.</p>
        </Tab>
        <Tab id='tab-class-types'>
          <p>Find the class that is right for you: <Link onClick={() => setFilterOpen(true)}>browse here</Link>.</p>
          <p>Group classes: the best environment for developing skills. Group classes usually focus on a specific medium or topic
            and run for 8 consecutive weeks (1 semester). Some group classes have pre-requisites, and all classes build back on themselves.
            For example, if you take Painting twice, the content will vary and the work will build on previous semesters. Courses tend
            to progress from Fall I through Spring II.  </p>
          <p>Punchcard: pay for group classes as a package (4, 8, 12), and take them on any of the standard weeks a group class runs.
            Great for tight schedules. Group class work is usually contained to 1 class, making it easier to pick and choose days as needed. </p>
          <p>Private Lessons: our semi-private lessons are always in a group of 4 students and always in a month package of 4 classes.
            These lessons are meant for portfolio or competition work, but also work well for achieving self-set goals.
            Choose what you want to study or what competitions you would like to participate in, or ask us to develop a personalized arts program. </p>
          <p>Workshops: want to enjoy the arts but never seem to find the time? These 3-4 hour long sessions are for you: fun and casual
            and full of new and exciting experiences. Participants leave all workshops with a finished, high quality product – an oil painting,
            a felted shawl, a painted batik scarf, or a piece of polymer jewelry. Workshops are sometimes taught by featured visiting artists. </p>
        </Tab>
        <Tab id='tab-testimonies'>
          <p> “Art wheel studio is amazing place for kids to learn and develop their creativity.
            Liliana is a very gifted teacher who is able to recognize my daughter’s needs and hone her drawing skills.
            We are extremely happy the we discovered Art Wheel studio” - Margarita T, 2019.</p>
          <p>“My teenage daughter has been a student at Art Wheel Studio for a few years now. She has learned so much from Ms. Liliana!
            Her growing portfolio is beautiful and her winning pieces make her whole family proud. The school's teaching techniques are
            really effective; my daughter's skills have grown exponentially and she looks forward to a career in the arts. Thank you, Art Wheel Studio!” – Leslie R, 2016</p>
          <p>“I love Liliana's art classes. My daughter was a student years ago until she graduated from high school.
            Liliana is an amazing teacher, who is very patient and encouraging to her students while teaching then much needed rounded skills.
            Also, she always has contests for them to participate in. I consider her a Master artist, and the most exceptional teacher any where.
            Your abilities will grow by leaps and bounds under her tutelage and watchful eye.” – Raelene S, 2016</p>
          <p>“Had a great experience with Art Wheel studio. I participated in a project making baby shower blanket keepsake.
            Had a lot of fun, that decided to schedule Birthday party for my mother in law where we did felting technique that was something new we all learned.
            Also my children went to kids art camp and enjoyed every part of it binning home clay art!!! Thank you Art Wheel studio for
            inspiring out creativity!!!” – Tanya C, 2016.</p>
        </Tab>
      </Tabs>
      <Popover className="filter" opened={filterOpen} onPopoverClosed={() => setFilterOpen(false)}>
        <Block>
          <BlockTitle>What Are You Interested In?</BlockTitle>
          <Filters showTypeFilter={true}/>
          <Row className='buttons'>
            <Button raised round onClick={() => setFilterOpen(false)}>Cancel</Button>
            <Button raised round onClick={() => {setFilterOpen(false);f7router.navigate('/browse');}}>Explore</Button>
          </Row>
        </Block>
      </Popover>
    </Fragment>
  );
}
