import React, {Fragment} from 'react';
import {useDispatch} from 'react-redux';
import {List, ListItem} from "framework7-react";
import {setProgram} from '../actions/shopping';

export default ({program, router, image}) => {
  const dispatch = useDispatch();
  if (program) {
    const {id, name, description} = program;
    const clickHandler = () => {
      if (program.items || (program.programs && program.programs.length === 1)) {
        const programToPass = program.programs ? program.programs[0] : program;
        const subtitle = program.programs ? name : undefined;
        // noinspection JSValidateTypes
        dispatch(setProgram(programToPass, subtitle));
        router.navigate('/product');
      } else {
        const buttons = program.programs.map(p => ({text: p.name}));
        const parameters = {
          title: 'Choose The Category',
          text: 'What would you like to see?',
          buttons: buttons,
          onClick: (dialog, index) => {
            // noinspection JSValidateTypes
            dispatch(setProgram(program.programs[index], name));
            router.navigate('/product');
          }
        };
        const {app} = router;
        const dialog = app.dialog.create(parameters);
        dialog.open(true);
      }
    };
    return (
      <List key={id} className="slightly-transparent" mediaList inset>
        <ListItem title={name} text={description} onClick={clickHandler}>
          <img className="main-menu-image" slot="media" src={image} alt={name}/>
        </ListItem>
      </List>
    );
  } else {
    return <Fragment/>
  }
}