import {List, ListInput} from "framework7-react";
import {getCategories, getLocations, getTypes, setCategory, setLocation, setType} from "../actions/shopping";
import React, {Fragment} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";

export default ({showTypeFilter}) => {
  const location = useSelector(state => state.shoppingContext.location, shallowEqual);
  const category = useSelector(state => state.shoppingContext.category, shallowEqual);
  const type = useSelector(state => state.shoppingContext.type, shallowEqual);
  const dispatch = useDispatch();

  const renderLocationFilter = () => {
    return (
      <ListInput
        type="select" defaultValue={location} label="Location:"
        onChange={e => dispatch(setLocation(e.target.value))}>
        {getLocations().map(({code, name}) => <option key={code} value={code}>{name}</option>)}
      </ListInput>
    );
  };

  const renderCategoryFilter =  () => {
    return (
      <ListInput
        type="select" defaultValue={category} label="Classes For:"
        onChange={e => dispatch(setCategory(e.target.value))}>
        {getCategories().map(({code, name}) => <option key={code} value={code}>{name}</option>)}
      </ListInput>
    );
  };

  const renderTypeFilter = () => {
    if (showTypeFilter) {
      return (
        <ListInput
          type="select" defaultValue={type} label="Type:"
          onChange={e => dispatch(setType(e.target.value))}>
          {getTypes().map(name => <option key={name} value={name}>{name}</option>)}
        </ListInput>
      );
    } else {
      return (
        <Fragment/>
      );
    }
  };

  return (
    <List inlineLabels noHairlines noHairlinesMd form>
      {renderLocationFilter()}
      {renderCategoryFilter()}
      {renderTypeFilter()}
    </List>
  );
};