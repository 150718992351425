import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import shallowEqual from "react-redux/es/utils/shallowEqual";
import {Navbar, NavRight, NavTitle} from "framework7-react";
import ShoppingCartIcon from "./ShoppingCartIcon";
import SettingsIcon from "./SettingsIcon";
import Filters from './Filters';

export default ({title, subtitle, hasBackLink, showFilters=false}) => {
  const checkoutInProgress = useSelector(state => state.checkout.inProgress, shallowEqual);
  const className = checkoutInProgress ? 'checkout-in-progress' : '';

  return (
    <Navbar className="navigation-bar" backLink={hasBackLink ? 'Back' : ''}>
      <NavTitle title={title} subtitle={subtitle}/>
      {showFilters ? <Filters showTypeFilter={showFilters}/> : <Fragment/>}
      <NavRight className={className}>
        <ShoppingCartIcon />
        <SettingsIcon />
      </NavRight>
    </Navbar>
  );
}
