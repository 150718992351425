import React from 'react';
import {useSelector} from 'react-redux';
import {Page, PageContent} from 'framework7-react';
import shallowEqual from "react-redux/es/utils/shallowEqual";
import NavigationBar from "../../components/NavigationBar";
import ApiError from '../../components/checkout/ApiError';
import ApiCall, {shouldMakeApiCall} from '../../components/checkout/ApiCall';
import CustomerInfo from "../../components/checkout/CustomerInfo";
import {checkIfExistingCustomer} from "../../actions/checkout";
import {handleReload} from "../../store";
import ReloadPage from "../ReloadPage";

export default ({f7router}) => {
  if (handleReload(useSelector(s => s, shallowEqual), f7router)) {return (<ReloadPage f7router={f7router}/>);}
  const colorTheme = useSelector(state => state.settings.colorTheme, shallowEqual);
  const settings = useSelector(state => state.settings, shallowEqual);
  const themeDark = useSelector(state => state.settings.themeDark, shallowEqual);
  const checkout = useSelector(state => state.checkout, shallowEqual);
  const items = useSelector(state => state.shoppingCart.items, shallowEqual);
  const {app} = f7router;
  const isApiCallEnabled = shouldMakeApiCall(app, checkout, "existingCustomer");

  return (
    <Page className='checkout checkout__customer_info' colorTheme={colorTheme} themeDark={themeDark}>
      <NavigationBar title="Contact Info" subtitle="Checkout: step 2 of 7" hasBackLink={true}/>
      <ApiError f7router={f7router}/>
      <ApiCall app={app} message="Checking e-mail ..." isEnabled={isApiCallEnabled} action={() => {
        return checkIfExistingCustomer(checkout.email, settings, items)
      }}
      />
      <PageContent>
        <CustomerInfo f7router={f7router}/>
      </PageContent>
    </Page>
  );
}