import {combineReducers} from "redux";
import programReducer from "./programReducer";
import discountReducer from "./discountReducer";
import shoppingCartReducer from "./shoppingCartReducer";
import shoppingContextReducer from "./shoppingContextReducer";
import settingsReducer from './settingsReducer';
import checkoutReducer from "./checkoutReducer";

const reducers = {
  programs: programReducer,
  discounts: discountReducer,
  shoppingCart: shoppingCartReducer,
  shoppingContext: shoppingContextReducer,
  checkout: checkoutReducer,
  settings: settingsReducer
};

export default () => {
  return combineReducers(reducers)
}