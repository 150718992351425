import React from 'react';
import {Block, Link, Page, PageContent, Tab, Tabs, Toolbar} from 'framework7-react';
import {useSelector} from "react-redux";
import shallowEqual from "react-redux/es/utils/shallowEqual";
import NavigationBar from '../components/NavigationBar';
import {handleReload} from "../store";
import ReloadPage from "./ReloadPage";
import lilianaImage from "../images/teachers/liliana.jpg";
import stanislavImage from "../images/teachers/stanislav.jpg";
import jenniferImage from "../images/teachers/jennifer.jpg";
import madeleineImage from "../images/teachers/madeleine.jpg";
import OurPrograms from "../components/OurPrograms";

export default ({f7router}) => {
  if (handleReload(useSelector(s => s, shallowEqual), f7router)) {
    return (<ReloadPage f7router={f7router}/>);
  }
  const colorTheme = useSelector(state => state.settings.colorTheme, shallowEqual);
  const themeDark = useSelector(state => state.settings.themeDark, shallowEqual);

  return (
    <Page className="desktop_about" colorTheme={colorTheme} themeDark={themeDark}>
      <NavigationBar title="About Us" hasBackLink={true}/>
      <PageContent>
        <Block className='our_story_teachers_and_programs'>
          <Block className='our_story'>
            <h2 className='our_story__header'>Our Story</h2>
            <div className='our_story__text'>
              <p>
                Art Wheel Studio is a family-owned arts academy, a place for anyone to start their
                journey in the fine or folk arts and traditional media.
                We teach various classes for children and adults by old-school European methods.
                AWS also offers semester-long seminars on various techniques and media,
                folk and traditional art workshops, and birthday and event parties.
              </p>
              <p>
                Founded by a Ukrainian mother and son in 2004, Art Wheel Studio continues to invite
                teachers and artists from various backgrounds and
                parts of the world to provide a diverse experience for our students.
              </p>
            </div>
          </Block>
          <Block className='our_teachers'>
            <h2 className='our_teachers__header'>Meet Our Teachers</h2>
            <Toolbar className='our_teachers__menu' tabbar tabbar-scrollable>
              <Link tabLink="#tab-liliana" tabLinkActive>Liliana</Link>
              <Link tabLink="#tab-stanislav">Stanislav</Link>
              <Link tabLink="#tab-teacher1">Jennifer</Link>
              <Link tabLink="#tab-teacher2">Madeleine</Link>
            </Toolbar>
            <Tabs className='our_teachers__content'>
              <Tab id='tab-liliana' tabActive>
                <h3>Liliana | Studio Owner</h3>
                <img src={lilianaImage} alt='Liliana'/>
                <Block>
                  <p>
                    A Ukrainian native, Liliana founded Art Wheel Studio in 2004 and incorporated it with
                    her son in 2015. Her desire to share her love of art with friends, family, and the next generation
                    has guided her growth as an artist and a teacher.
                  </p>
                  <p>
                    The fine and folk arts have enamored Liliana since she was a kid.
                    While still in Ukraine, she practiced art but also worked as a fashion designer and floral arranger.
                    Though she finished art school and the Kiev Cultural Institute with a Masters
                    in Teaching Fine Arts, life has taken her on many paths.
                    After starting a family and immigrating to the United States, Liliana introduced first her
                    children and then their friends to her art.
                    Since then she has been crafting her signature teaching method, combining her European school with
                    her experience teaching in the States.
                    Her students have placed in multiple international and national competitions.
                  </p>
                  <p>
                    Liliana represented Texas at the World Children’s Festival in DC in 2007, 2011, and 2015
                    as an art teacher with her students and provided a number of workshops.
                    Her students formed WizArt Club based on the WCF trips in 2007 and chose Liliana as their mentor.
                    She still helps with the operations of the club.
                  </p>
                </Block>
              </Tab>
              <Tab id='tab-stanislav'>
                <h3>Stanislav | Studio Owner</h3>
                <img src={stanislavImage} alt='Stanislav'/>
                <Block>
                  <p>
                    Surrounded by art since childhood, Stanislav has pursued it throughout his life:
                    whether in drawing and painting, architecture, design, west coast swing and ballroom dance, music or
                    singing. His favorite work relates to the human body and he devotes many of his studies to
                    portraiture,
                    figure, movement, and the academic method.</p>
                  <p>
                    Stanislav received his Bachelor of Architecture from the Syracuse University
                    School of Architecture. Since then, he has consulted on the designs of various built projects.
                    He designed and oversaw both the Roanoke and the Plano AWS renovations.
                    Stanislav also studied art and architecture in Florence, Italy.
                  </p>
                  <p>
                    Stanislav’s art, design, and other work can be found at
                    <Link href="http://nedzikdesign.com/" external target="_blank"> http://nedzikdesign.com/</Link>
                  </p>
                </Block>
              </Tab>
              <Tab id='tab-teacher1'>
                <h3>Jennifer | Art Teacher</h3>
                <img src={jenniferImage} alt='Jennifer'/>
                <Block>
                  <p>
                    An artist with a diverse background, Jennifer is skilled in both traditional and digital media.
                    Throughout college at Lock Haven and SMU, she explored and wrote about game design, color theory,
                    and the effects of art on people. She also has experience in the fashion industry,
                    having managed a folk art clothing and accessories store in the past.
                  </p>
                  <p>
                    Jennifer enjoys making art and sharing her process with others via streams and videos. Her work
                    focuses on fantasy, but is built on studies of nature and form.
                  </p>
                  <p>
                    Jennifer joined AWS at the end of 2019. She holds a Masters in Interactive Entertainment.
                  </p>
                </Block>
              </Tab>
              <Tab id='tab-teacher2'>
                <h3>Madeleine | Art Teacher</h3>
                <img src={madeleineImage} alt='Madeleine'/>
                <Block>
                  <p>
                    Madeleine is an Art Wheel Studio alumni, having studied here for over 10 years,
                    and is thrilled to teach art to the next generation of AWS. She has won many local, national,
                    and international contests such as the Fort Worth Stock Show Art Show,
                    Wyland Art Foundation Art Competition, and International Picasso Watercolor
                    Competition. She enjoys painting portraits and figures in watercolor, and crafting
                    narrative work. Madeleine teaches beginner watercolors and kids classes.
                  </p>
                  <p>
                    Madeleine has participated in WizArt since 2014 and has been its president since 2020.
                  </p>
                </Block>
              </Tab>
            </Tabs>
          </Block>
          <Block className='our_program'>
            <h2 className='our_program__header'>Learn about our Program</h2>
            <OurPrograms className='our_program__content' f7router={f7router}/>
          </Block>
        </Block>
      </PageContent>
    </Page>
  );
};
