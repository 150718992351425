import React, {useRef, useState} from 'react';
import {Block, BlockTitle, Link, List, ListInput, ListItem, Page, PageContent} from 'framework7-react';
import {useDispatch, useSelector} from "react-redux";
import shallowEqual from "react-redux/es/utils/shallowEqual";
import NavigationBar from "../../components/NavigationBar";
import CheckoutButtons from "../../components/checkout/CheckoutButtons";
import {setContactEmail} from "../../actions/checkout";
import {pathOr} from 'ramda';
import {handleReload} from "../../store";
import ReloadPage from "../ReloadPage";
import {isDesktop} from "../../utils";

export default ({f7router}) => {
  if (handleReload(useSelector(s => s, shallowEqual), f7router)) {return (<ReloadPage f7router={f7router}/>);}
  const settings = useSelector(state => state.settings, shallowEqual);
  const checkout = useSelector(state => state.checkout, shallowEqual);
  const themeDark = useSelector(state => state.settings.themeDark, shallowEqual);
  const dispatch = useDispatch();
  const emailElement = useRef(null);
  const [acceptedEula, setAcceptedEula] = useState(pathOr(false, ['acceptedEula'], checkout));
  const [storeEmailInSettings, setStoreEmailInSettings] = useState(pathOr('', ['email'], settings) !== '');
  const [email, setEmail] = useState(pathOr(settings.email, ['email'], checkout));
  const [hasValidEmail, setHasValidEmail] = useState(email);
  const canProceed = hasValidEmail && acceptedEula;
  const continueText = isDesktop(f7router) ? 'Checkout >' : 'Your Info >'
  const subtitle = `Checkout: step 1 of ${isDesktop(f7router) ? 2 : 7}`


  const continueHandler = () => {
    dispatch(setContactEmail(email, acceptedEula, storeEmailInSettings));
    const url = `/checkout/${isDesktop(f7router) ? 'desktop' : 'customer'}`
    f7router.navigate(url);
  };

  const emailChangeHandler = e => {
    setEmail(e.target.value);
    setHasValidEmail(pathOr(false, ['current', '__reactRefs', 'inputEl', 'validity', 'valid'], emailElement));
  };

  return (
    <Page className='checkout checkout__email' colorTheme={settings.colorTheme} themeDark={themeDark}>
      <NavigationBar title="Contact E-mail" subtitle={subtitle} hasBackLink={false}/>
      <PageContent>
        <Block>
          <BlockTitle>What is your e-mail address?</BlockTitle>
          <List noHairlines noHairlinesMd form>
            <ListInput
              type="email" value={email} placeholder="Your e-mail" label="Your e-mail" floatingLabel
              clearButton errorMessage="Please, provide a valid e-mail address"
              ref={emailElement} required validate onChange={emailChangeHandler}
              onInputClear={() => {
                setEmail('');
                setHasValidEmail(false)
              }}
            />
            <ListItem checkbox onChange={e => setAcceptedEula(e.target.checked)} checked={acceptedEula}>
              <div className="accept-eula">
                To continue, please, accept our&nbsp;
                <Link href="terms-and-conditions-2019.pdf" external={true} target="_blank">
                  terms and conditions
                </Link>
              </div>
            </ListItem>
            <ListItem
              checkbox tabindex="2" checked={storeEmailInSettings}
              onChange={e => setStoreEmailInSettings(e.target.checked)}>
              Store my e-mail in settings for quicker checkouts in the future
            </ListItem>
            <CheckoutButtons
              f7router={f7router} canProceed={canProceed} continueText={continueText}
              continueHandler={continueHandler}
            />
          </List>
        </Block>
      </PageContent>
    </Page>
  );
}