import React, {useRef} from 'react';
import {useSelector} from "react-redux";
import shallowEqual from "react-redux/es/utils/shallowEqual";
import {Page, PageContent, List, ListItem, PhotoBrowser, Block} from 'framework7-react';
import toddlersImage from '../images/gallery/toddlers.jpg';
import kidsImage from '../images/gallery/kids.jpg';
import teenagersImage from '../images/gallery/teenagers.jpg';
import awardsImage from '../images/gallery/awards.jpg';
import portfoliosImage from '../images/gallery/portfolios.jpg';
import publicationsImage from '../images/gallery/publications.jpg';
import NavigationBar from "../components/NavigationBar";
import {handleReload} from "../store";
import ReloadPage from "./ReloadPage";

export default ({f7router}) => {
  if (handleReload(useSelector(s => s, shallowEqual), f7router)) {return (<ReloadPage f7router={f7router}/>);}
  const colorTheme = useSelector(state => state.settings.colorTheme, shallowEqual);
  const themeDark = useSelector(state => state.settings.themeDark, shallowEqual);
  const toddlers = useRef(null);
  const kids = useRef(null);
  const teenagers = useRef(null);
  const awards = useRef(null);
  const portfolios = useRef(null);
  const publications = useRef(null);

  const base = 'images/gallery';
  const toddlerPhotos = [
    {url: `${base}/toddlers/image1.jpg`, caption: 'Caption 1'},
    {url: `${base}/toddlers/image2.jpg`, caption: 'Caption 2'},
    {url: `${base}/toddlers/image3.jpg`, caption: 'Caption 3'},
    {url: `${base}/toddlers/image4.jpg`, caption: 'Caption 4'},
    {url: `${base}/toddlers/image5.jpg`, caption: 'Caption 5'},
    {url: `${base}/toddlers/image6.jpg`, caption: 'Caption 6'},
    {url: `${base}/toddlers/image7.jpg`, caption: 'Caption 7'},
    {url: `${base}/toddlers/image8.jpg`, caption: 'Caption 8'},
    {url: `${base}/toddlers/image9.jpg`, caption: 'Caption 9'},
  ];

  const kidsPhotos = [
    {url: `${base}/kids/image1.jpg`, caption: 'Caption 1'},
    {url: `${base}/kids/image2.jpg`, caption: 'Caption 2'},
    {url: `${base}/kids/image3.jpg`, caption: 'Caption 3'},
    {url: `${base}/kids/image4.jpg`, caption: 'Caption 4'},
    {url: `${base}/kids/image5.jpg`, caption: 'Caption 5'},
    {url: `${base}/kids/image6.jpg`, caption: 'Caption 6'},
    {url: `${base}/kids/image7.jpg`, caption: 'Caption 7'},
    {url: `${base}/kids/image8.jpg`, caption: 'Caption 8'},
    {url: `${base}/kids/image9.jpg`, caption: 'Caption 9'},
  ];

  const teenagersPhotos = [
    {url: `${base}/teenagers/image1.jpg`, caption: 'Caption 1'},
    {url: `${base}/teenagers/image2.jpg`, caption: 'Caption 2'},
    {url: `${base}/teenagers/image3.jpg`, caption: 'Caption 3'},
    {url: `${base}/teenagers/image4.jpg`, caption: 'Caption 4'},
    {url: `${base}/teenagers/image5.jpg`, caption: 'Caption 5'},
    {url: `${base}/teenagers/image6.jpg`, caption: 'Caption 6'},
    {url: `${base}/teenagers/image7.jpg`, caption: 'Caption 7'},
    {url: `${base}/teenagers/image8.jpg`, caption: 'Caption 8'},
    {url: `${base}/teenagers/image9.jpg`, caption: 'Caption 9'},
  ];

  const awardsPhotos = [
    {url: `${base}/awards/image1.jpg`, caption: 'Caption 1'},
    {url: `${base}/awards/image2.jpg`, caption: 'Caption 2'},
    {url: `${base}/awards/image3.jpg`, caption: 'Caption 3'},
    {url: `${base}/awards/image4.jpg`, caption: 'Caption 4'},
    {url: `${base}/awards/image5.jpg`, caption: 'Caption 5'},
    {url: `${base}/awards/image6.jpg`, caption: 'Caption 6'},
    {url: `${base}/awards/image7.jpg`, caption: 'Caption 7'},
    {url: `${base}/awards/image8.jpg`, caption: 'Caption 8'},
    {url: `${base}/awards/image9.jpg`, caption: 'Caption 9'},
  ];

  const portfoliosPhotos = [
    {url: `${base}/portfolios/image1.jpg`, caption: 'Caption 1'},
    {url: `${base}/portfolios/image2.jpg`, caption: 'Caption 2'},
    {url: `${base}/portfolios/image3.jpg`, caption: 'Caption 3'},
    {url: `${base}/portfolios/image4.jpg`, caption: 'Caption 4'},
    {url: `${base}/portfolios/image5.jpg`, caption: 'Caption 5'},
    {url: `${base}/portfolios/image6.jpg`, caption: 'Caption 6'},
    {url: `${base}/portfolios/image7.jpg`, caption: 'Caption 7'},
    {url: `${base}/portfolios/image8.jpg`, caption: 'Caption 8'},
    {url: `${base}/portfolios/image9.jpg`, caption: 'Caption 9'},
  ];

  const publicationsPhotos = [
    {url: `${base}/publications/image1.jpg`, caption: 'Caption 1'},
    {url: `${base}/publications/image2.jpg`, caption: 'Caption 2'},
    {url: `${base}/publications/image3.jpg`, caption: 'Caption 3'},
    {url: `${base}/publications/image4.jpg`, caption: 'Caption 4'},
    {url: `${base}/publications/image5.jpg`, caption: 'Caption 5'},
    {url: `${base}/publications/image6.jpg`, caption: 'Caption 6'},
    {url: `${base}/publications/image7.jpg`, caption: 'Caption 7'},
    {url: `${base}/publications/image8.jpg`, caption: 'Caption 8'},
    {url: `${base}/publications/image9.jpg`, caption: 'Caption 9'},
  ];

  return (
    <Page className='gallery' colorTheme={colorTheme} themeDark={themeDark}>
      <NavigationBar title="Gallery" hasBackLink={true}/>
      <PageContent>
        <Block>
          <List className="slightly-transparent" mediaList inset>
            <PhotoBrowser photos={toddlerPhotos} type="popup" backLinkText="Back" ref={toddlers}/>
            <ListItem title="Ages 2 to 5" text="The first steps in the world of art" onClick={() => toddlers.current.open()}>
              <img className="gallery-image" slot="media" src={toddlersImage} alt="Ages 2 to 5"/>
            </ListItem>
            <PhotoBrowser photos={kidsPhotos} type="popup" backLinkText="Back" ref={kids}/>
            <ListItem title="Ages 5 to 12" text="From beginners to young artists" onClick={() => kids.current.open()}>
              <img className="gallery-image" slot="media" src={kidsImage} alt="Ages 5 to 12"/>
            </ListItem>
            <PhotoBrowser photos={teenagersPhotos} type="popup" backLinkText="Back" ref={teenagers}/>
            <ListItem title="Ages 12+" text="Out into the wild" onClick={() => teenagers.current.open()}>
              <img className="gallery-image" slot="media" src={teenagersImage} alt="Ages 12+"/>
            </ListItem>
          </List>
          <List className="slightly-transparent" mediaList inset>
            <PhotoBrowser photos={awardsPhotos} type="popup" backLinkText="Back" ref={awards}/>
            <ListItem title="Awards, Awards" text="Our winners and their works" onClick={() => awards.current.open()}>
              <img className="gallery-image" slot="media" src={awardsImage} alt="Awards, Awards"/>
            </ListItem>
            <PhotoBrowser photos={portfoliosPhotos} type="popup" backLinkText="Back" ref={portfolios}/>
            <ListItem title="Portfolios" text="Working on high-school portfolios" onClick={() => portfolios.current.open()}>
              <img className="gallery-image" slot="media" src={portfoliosImage} alt="Portfolios"/>
            </ListItem>
            <PhotoBrowser photos={publicationsPhotos} type="popup" backLinkText="Back" ref={publications}/>
            <ListItem title="Publications" text="Children for children" onClick={() => publications.current.open()}>
              <img className="gallery-image" slot="media" src={publicationsImage} alt="Publications"/>
            </ListItem>
          </List>
        </Block>
      </PageContent>
    </Page>
  );
};