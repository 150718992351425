import React from 'react';
import {useSelector} from "react-redux";
import shallowEqual from "react-redux/es/utils/shallowEqual";
import {Page, PageContent} from 'framework7-react';
import NavigationBar from '../components/NavigationBar';
import {handleReload} from "../store";
import ReloadPage from "./ReloadPage";
import ContactUs from "../components/ContactUs";

export default ({f7router}) => {
  if (handleReload(useSelector(s => s, shallowEqual), f7router)) {return (<ReloadPage f7router={f7router}/>);}
  const settings = useSelector(state => state.settings, shallowEqual);

  return (
    <Page className='contact' colorTheme={settings.colorTheme} themeDark={settings.themeDark}>
      <NavigationBar title="Contact Us" hasBackLink={true}/>
      <PageContent>
        <ContactUs f7router={f7router} settings={settings} showHeader={false} />
      </PageContent>
    </Page>
  );
};
