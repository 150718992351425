import {
  APPLY_COUPON_FAILURE,
  APPLY_COUPON_SUCCESS,
  BOOK_FAILURE,
  BOOK_SUCCESS,
  CALCULATE_DISCOUNTS_FAILURE,
  CALCULATE_DISCOUNTS_SUCCESS,
  CANCEL_CHECKOUT,
  CHECK_IF_EXISTING_CUSTOMER_FAILURE,
  CHECK_IF_EXISTING_CUSTOMER_SUCCESS,
  CLEAR_COUPON,
  CLEAR_ERROR,
  COMPLETE_CHECKOUT,
  SET_CONTACT_EMAIL,
  SET_CUSTOMER_INFO,
  SET_FORM_OF_PAYMENT,
  SET_STUDENT_NAME,
  START_CHECKOUT
} from "../actions/types";

const startCheckout = (state, {shoppingCart}) => {
  const items = shoppingCart.items.map(i => ({...i, student: {sameAsUser: true, name: ''}}));
  return {...state, shoppingCart: {...shoppingCart, items}, inProgress: true, formOfPayment: 'bank'};
};

const setStudentName = (state, {index, sameAsUser, name}) => {
  const {shoppingCart} = state;
  const items = shoppingCart.items.map((item, i) => i === index ? {...item, student: {sameAsUser, name}} : item);
  return {...state, shoppingCart: {...shoppingCart, items}};
};

export default (state = [], action) => {
  switch (action.type) {
    case START_CHECKOUT:
      return startCheckout(state, action);
    case SET_CONTACT_EMAIL:
      const {email, acceptedEula} = action;
      const hasEmailChanged = state.email !== email;
      const updatedState = {...state, email, acceptedEula};
      if (hasEmailChanged) {
        const {existingCustomer, ...rest} = updatedState;
        return rest;
      }
      return updatedState;
    case SET_STUDENT_NAME:
      return setStudentName(state, action);
    case SET_CUSTOMER_INFO:
      const {firstName, lastName, phone} = action;
      return {...state, customerInfo: {firstName, lastName, phone}};
    case CANCEL_CHECKOUT:
    case COMPLETE_CHECKOUT:
      return {inProgress: false};
    case CHECK_IF_EXISTING_CUSTOMER_SUCCESS:
      const {payload: {data: {existingCustomer, registrationFee, creditAmount }}} = action;
      return {...state, existingCustomer, registrationFee, credit: { creditAmount, apply: true}};
    case CHECK_IF_EXISTING_CUSTOMER_FAILURE:
    case CALCULATE_DISCOUNTS_FAILURE:
      return {...state, error: action.error};
    case CALCULATE_DISCOUNTS_SUCCESS:
      const {payload: {data: {discounts}}} = action;
      return {...state, discounts};
    case APPLY_COUPON_SUCCESS:
      const {payload: {data: {couponCode, amount}}} = action;
      return {...state, coupon: { code: couponCode, amount}};
    case APPLY_COUPON_FAILURE:
      if (action.error.response.status === 406) {
        return {...state, coupon: {errorMessage: 'Not a valid coupon code'}}
      } else {
        return {...state, error: action.error};
      }
    case CLEAR_COUPON:
      const {coupon, ...rest} = state;
      return rest;
    case SET_FORM_OF_PAYMENT:
      const {formOfPayment} = action;
      return {...state, formOfPayment};
    case BOOK_SUCCESS:
      const {payload: {data: {invoiceId, emailSent}}} = action;
      return {...state, bookingResult: {invoiceId, emailSent}};
    case BOOK_FAILURE:
      return {...state, bookingResult: {error: action.error}};
    case CLEAR_ERROR:
      const {error, ...restWithoutError} = state;
      return restWithoutError;
    default:
      return state;
  }
};