import React from 'react';
import {useSelector} from "react-redux";
import shallowEqual from "react-redux/es/utils/shallowEqual";
import {Block, Page, PageContent} from 'framework7-react';
import NavigationBar from '../components/NavigationBar';
import LocationsAndContacts from "../components/LocationsAndContacts";
import {handleReload} from "../store";
import ReloadPage from "./ReloadPage";
import ContactUs from "../components/ContactUs";

export default ({f7router}) => {
  if (handleReload(useSelector(s => s, shallowEqual), f7router)) {return (<ReloadPage f7router={f7router}/>);}
  const settings = useSelector(state => state.settings, shallowEqual);
  const colorTheme = useSelector(state => state.settings.colorTheme, shallowEqual);

  return (
    <Page className='contact_us_and_locations' colorTheme={settings.colorTheme} themeDark={settings.themeDark}>
      <NavigationBar title="Contact Us" hasBackLink={true}/>
      <PageContent>
        <Block>
          <ContactUs f7router={f7router} settings={settings}/>
          <Block className='locations'>
            <h2 className='locations__header'>Our Locations and Contact Info</h2>
            <LocationsAndContacts colorTheme={colorTheme}/>
          </Block>
        </Block>
      </PageContent>
    </Page>
  );
};
