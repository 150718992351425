import {APPLY_AND_SAVE_SETTINGS, INITIAL_LOAD_SUCCESS, SET_CONTACT_EMAIL} from "../actions/types";
import { window } from 'ssr-window';

const applySettings = (state, action) => {
  const {email, colorTheme, themeDark} = action;
  return {...state, email, colorTheme, themeDark};
};

const KEY = `artwheelstudio-settings`;

const saveSettings = newState => {
  try {
    window.localStorage[KEY] = JSON.stringify(newState);
    return newState;
  } catch (e) {
    throw e;
  }
};

const loadSettings = () => {
  try {
    if (window.localStorage[KEY]) {
      return JSON.parse(window.localStorage[KEY]);
    } else {
      return {};
    }
  } catch (e) {
    throw e;
  }
};


export default (state = [], action) => {
  switch (action.type) {
    case INITIAL_LOAD_SUCCESS:
      return {...state, ...loadSettings(), api: action.payload.data.api};
    case APPLY_AND_SAVE_SETTINGS:
      return saveSettings(applySettings(state, action));
    case SET_CONTACT_EMAIL:
      const {email, storeEmailInSettings} = action;
      return saveSettings({...state, email: storeEmailInSettings ? email : ''});
    default:
      return state;
  }
};