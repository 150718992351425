import React, {useState} from 'react';
import {Col, Input, Row, Checkbox} from "framework7-react";
import {isForAdults, setStudentName} from "../../actions/checkout";
import {useDispatch} from "react-redux";

export default ({item, index}) => {
  const {product, offer, student} = item;
  const {name : productName, price} = product;
  const {sku} = product;
  const [name, setName] = useState(student.name);
  const isNameInputEnabled = sameAsUser => ! (isForAdults(sku) && sameAsUser);
  const [inputEnabled, setInputEnabled] = useState(isNameInputEnabled(student.sameAsUser));
  const dispatch = useDispatch();

  const checkboxChangeHandler = e => {
    setInputEnabled(isNameInputEnabled(e.target.checked));
    if (e.target.checked) {
      setName('');
    }
    // noinspection JSValidateTypes
    dispatch(setStudentName(index, e.target.checked, name));
  };

  const textChangeHandler = e => {
    setName(e.target.value);
    // noinspection JSValidateTypes
    dispatch(setStudentName(index, !inputEnabled, e.target.value));
  };

  const textClearHandler = () => {
    setName('');
    // noinspection JSValidateTypes
    dispatch(setStudentName(index, false, ''));
  };

  return (
    <Row key={index + 1} className='checkout-details-products-data'>
      <Col width='25'>{productName}</Col>
      <Col width='30'>{offer}</Col>
      <Col width='10'>{`$${Number.parseFloat(price).toFixed(2)}`}</Col>
      <Col className='checkout-details-products-data-same-as-student' width='10'>
        <Checkbox
          onChange={checkboxChangeHandler} defaultChecked={isForAdults(sku)}
          disabled={!isForAdults(sku)}/>
      </Col>
      <Col width='20'>
        <Input
          type="text" label="" value={name} placeholder=""
          onInputClear={textClearHandler} required validate
          disabled={!inputEnabled} onChange={textChangeHandler}
        />
      </Col>
    </Row>
  )
}

export const renderHeader = () => {
  return (
    <Row index='0' className='checkout-details-products-header'>
      <Col width='25'>Product</Col>
      <Col width='30'>Day/Time</Col>
      <Col width='10'>Price</Col>
      <Col className='checkout-details-products-header-same-as-student' width='10'>I'm the student</Col>
      <Col width='20'>Student's Name</Col>
    </Row>
  );
}