import React, {Fragment, useState} from 'react';
import {Block, BlockTitle, Button, List, ListInput, Row} from "framework7-react";
import CheckoutButtons from "./CheckoutButtons";
import {useDispatch, useSelector} from "react-redux";
import shallowEqual from "react-redux/lib/utils/shallowEqual";
import {pathOr} from "ramda";
import ApiCall, {shouldMakeApiCall} from "./ApiCall";
import {applyCoupon, clearCoupon, clearError} from "../../actions/checkout";

export default ({f7router, showButtons=true}) => {
  const checkout = useSelector(state => state.checkout, shallowEqual);
  const settings = useSelector(state => state.settings, shallowEqual);
  const items = pathOr([], ['shoppingCart', 'items'], checkout);
  const errorMessage = pathOr('', ['coupon', 'errorMessage'], checkout);
  const [couponCode, setCouponCode] = useState(pathOr('', ['coupon', 'code'], checkout));
  const [validationInProcess, setValidationInProcess] = useState(false);
  const validateLabel = 'Validate ...';
  const readyForDiscounts = couponCode.length === 0 ||
    pathOr('', ['coupon', 'code'], checkout) === couponCode.toUpperCase();
  const continueText = readyForDiscounts ? 'Discounts >' : validateLabel;
  const {app} = f7router;
  const dispatch = useDispatch();
  const isApiCallEnabled = shouldMakeApiCall(app, checkout, "coupon" );

  const continueHandler = () => {
    if (continueText === validateLabel) {
      setValidationInProcess(true);
    } else {
      f7router.navigate('/checkout/discounts');
    }
  };

  const onChangeHandler = event =>  {
    // noinspection JSValidateTypes
    dispatch(clearError());
    setCouponCode(event.target.value);
    // noinspection JSValidateTypes
    dispatch(clearCoupon())
  };

  const onClearHandler = () => {
    // noinspection JSValidateTypes
    dispatch(clearCoupon());
    setCouponCode('');
  };

  const renderCouponValidation = () => {
    if (validationInProcess) {
      return (
        <ApiCall
          app={app} message="Validating coupon ..." isEnabled={isApiCallEnabled}
          cleanup={() => setValidationInProcess(false)}
          action={() => {return applyCoupon(couponCode, items, settings)}}
        />
      )
    } else {
      return (
        <Fragment/>
      )
    }
  };

  const renderCouponInfo = () => {
    const amount = pathOr(Number.MIN_VALUE, ['coupon', 'amount'], checkout);
    if (amount !== Number.MIN_VALUE) {
      return (
        <p>
          {`Congratulations! We will subtract $${Number.parseFloat(amount).toFixed(2)} from your total.`}
        </p>
      )
    } else {
      return (
        <Fragment/>
      )
    }
  };

  const renderCheckoutButtons = () => {
    if (showButtons) {
      return (
        <CheckoutButtons
          f7router={f7router} canProceed={true} continueText={continueText}
          continueHandler={continueHandler}
        />
      );
    } else {
      return (
        <Row className="checkout-buttons">
            <Button className="continue-button" raised round onClick={() => setValidationInProcess(true)}>
              {validateLabel}
            </Button>
        </Row>
      );
    }
  }

  const renderMessage = () => {
    if (showButtons) {
      return (
        <p>
          Please, enter coupon code below and then click <b>"Validate"</b>.
          If you do not have a coupon, click <b>"Discounts"</b> to proceed to the next step.
        </p>
      );
    } else {
      return (
        <p>
          Please, enter coupon code below and then click <b>"Validate"</b>.
        </p>
      );
    }
  }

  return (
    <Fragment>
      {renderCouponValidation()}
      <Block>
        <BlockTitle>Do you have any coupons?</BlockTitle>
        {renderMessage()}
        <List noHairlines noHairlinesMd form>
          <ListInput
            type="text" key='1' label="Coupon Code" floatingLabel value={couponCode} placeholder="Coupon Code"
            errorMessage={errorMessage}  errorMessageForce={errorMessage.length > 0}
            clearButton onInputClear={onClearHandler} onChange={onChangeHandler}
          />
        </List>
        {renderCouponInfo()}
        {renderCheckoutButtons()}
      </Block>
    </Fragment>
  );
}