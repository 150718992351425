import React, {useState} from 'react';
import {
  Block,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  Link,
  Navbar,
  NavRight,
  Page,
  Popup,
  Row
} from "framework7-react";
import Offers from "./Offers";

export default ({product, capacities, f7router}) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const {name, description, shortDescription, specificOffers, imageName, price} = product;
  return (
    <Card className="card-header-with-picture">
      <CardHeader
        className="no-border" valign="bottom"
        style={{ backgroundImage: `url(images/programs/${imageName}.jpg)` }}>
        <Row className='card-title'>{name}</Row>
      </CardHeader>
      <CardContent>
        <Row><p><b>{`$${Number.parseFloat(price).toFixed(0)}`}.&nbsp;</b>{shortDescription}</p></Row>
        <Row><Link raised round onClick={() => setPopupOpen(true)}>Read More</Link></Row>
        <Popup className="read-more-popup" opened={popupOpen} onPopupClosed={() => setPopupOpen(false)}>
          <Page>
            <Navbar title={name}>
              <NavRight>
                <Link onClick={() => setPopupOpen(false)}>Close</Link>
              </NavRight>
            </Navbar>
            <Block>
              <Row className='short-description'>
                <p key={0}>{shortDescription}</p>
              </Row>
              {description.map((d,i) => (<Row><p key={i+1}>{d}</p></Row>))}
            </Block>
            <Block>
              <ul>
                {specificOffers.map((d,i) => (<li key={i}>{d}</li>))}
              </ul>
            </Block>
          </Page>
        </Popup>
      </CardContent>
      <CardFooter>
        <Offers product={product} offers={specificOffers} capacities={capacities} f7router={f7router}/>
      </CardFooter>
    </Card>
  );
}
