import React, {Fragment, useEffect, useRef} from 'react';
import {Page, PageContent} from 'framework7-react';
import semesterImage from '../images/classes.jpg';
import privateLessonsImage from '../images/private.jpg';
import campsImage from '../images/camps.jpg';
import {shallowEqual, useSelector} from 'react-redux';
import NavigationBar from "../components/NavigationBar";
import FloatingMenuButton from '../components/FloatingMenuButton';
import ProgramItem from "../components/ProgramItem";
import {getCategoryName, getLocationName, SEMESTER_CODES} from "../actions/shopping";
import {has, pathOr} from 'ramda';
import {handleReload} from "../store";
import ReloadPage from "./ReloadPage";

export default ({f7router}) => {
  if (handleReload(useSelector(s => s, shallowEqual), f7router)) {return (<ReloadPage f7router={f7router}/>);}
  const colorTheme = useSelector(state => state.settings.colorTheme, shallowEqual);
  const themeDark = useSelector(state => state.settings.themeDark, shallowEqual);
  const programs = useSelector(state => state.programs, shallowEqual);
  const location = useSelector(state => state.shoppingContext.location, shallowEqual);
  const category = useSelector(state => state.shoppingContext.category, shallowEqual);
  const subtitle = `${getLocationName(location)}, ${getCategoryName(category)}`;

  const matchesLocationAndCategory = sku => sku.substring(0, 2) === location && category.includes(sku.substring(2, 3));

  const filterByLocationAndCategory = program => {
    if (has('items', program)) {
      const items = program.items.filter(i => matchesLocationAndCategory(pathOr('ROZ', ['sku'], i)));
      return {...program, items}
    }
    if (has('programs', program)) {
      const programs = program.programs.map(p => filterByLocationAndCategory(p));
      return {...program, programs}
    }
    return program;
  };

  const isEmpty = program => {
    if (has('items', program)) {
      return program.items.length === 0;
    }
    if (has('programs', program)) {
      return ! program.programs.some(p => ! isEmpty(p));
    }
    return program;
  };

  const renderSemesters = semesters => {
    const nonEmptySemesters = semesters.filter(s => ! isEmpty(s));
    if ( nonEmptySemesters) {
      return (
        <Fragment>
          {nonEmptySemesters.map(s => <ProgramItem key={s.id} program={s} router={f7router} image={semesterImage} />)}
        </Fragment>
      );
    }
  };

  const renderProgram = (program, image) => {
    if (! isEmpty(program)) {
      return (
        <ProgramItem program={program} router={f7router} image={image} />
      )
    }
  };

  const semesters = programs.filter(p => SEMESTER_CODES.indexOf(p.id) !== -1).map(p => filterByLocationAndCategory(p));
  const privateLessons = filterByLocationAndCategory(programs.find(p => p.id === 'privateLessons'));
  const camps = filterByLocationAndCategory(programs.find(p => p.id === 'camps'));
  const {app} = f7router;
  const alertIsClosed = app.dialog.get('.dialog-buttons-1') === undefined;
  const pageElement = useRef();

  useEffect(() => {
    if (semesters.every(s => isEmpty(s)) && isEmpty(privateLessons) && isEmpty(camps)) {
      const {currentRoute: {path}, currentPageEl} = f7router;
      const el = pathOr(null, ['current', '__reactRefs', 'el'], pageElement);
      if (path === '/catalog' && alertIsClosed && el === currentPageEl) {
        const message = `We could not find any products for ${subtitle}. Please, try different search options`;
        app.dialog.alert(message, 'Warning', () => f7router.navigate('/'))
      }
    }
  });

  const renderContent = () => {
    if (programs.length) {
      if (semesters.every(s => isEmpty(s)) && isEmpty(privateLessons) && isEmpty(camps)) {
        return (
          <Fragment/>
        );
      } else {
        return (
          <div className='catalog-content'>
            {renderSemesters(semesters)}
            {renderProgram(privateLessons, privateLessonsImage)}
            {renderProgram(camps, campsImage)}
          </div>
        );
      }
    } else {
      return (
        <Fragment/>
      );
    }
  };

  return (
    <Page className="catalog-page" colorTheme={colorTheme} themeDark={themeDark} ref={pageElement}>
      <NavigationBar title="Art Wheel Studio" subtitle={subtitle} hasBackLink={true}/>
      <PageContent>
        {renderContent()}
      </PageContent>
      <FloatingMenuButton f7router={f7router}/>
    </Page>
  );
}

