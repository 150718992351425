import React, {Fragment} from 'react';
import {Block, BlockTitle, Button, Icon, List, ListItem, Page, PageContent, Row} from 'framework7-react';
import {useDispatch, useSelector} from "react-redux";
import shallowEqual from "react-redux/es/utils/shallowEqual";
import NavigationBar from "../../components/NavigationBar";
import {completeCheckout} from "../../actions/checkout";
import {pathOr} from "ramda";
import {handleReload} from "../../store";
import ReloadPage from "../ReloadPage";

export default ({f7router}) => {
  if (handleReload(useSelector(s => s, shallowEqual), f7router)) {return (<ReloadPage f7router={f7router}/>);}
  const checkout = useSelector(state => state.checkout, shallowEqual);
  const themeDark = useSelector(state => state.settings.themeDark, shallowEqual);
  const colorTheme = useSelector(state => state.settings.colorTheme, shallowEqual);
  const dispatch = useDispatch();
  const invoiceNumber = pathOr(0, ['bookingResult', 'invoiceId'], checkout);
  const emailSent = pathOr(false, ['bookingResult', 'emailSent'], checkout);
  const error = pathOr(undefined, ['bookingResult', 'error'], checkout);

  const getEmailSentMessage = () => {
    if (emailSent) {
      return 'We have sent you an e-mail with the invoice attached.'
    } else {
      return 'We failed to e-mail you the invoice. We will try to reach you by phone.'
    }
  };

  const renderIcon = success => {
    return (
      <Icon f7={success ? 'check' : 'close'} colorTheme={colorTheme} />
    );
  };

  const renderResults = () => {
    if (error) {
      return (
        <p>
          Unfortunately, something went wrong and we could not process your booking.
          We apologize for the inconvenience.
          Please, call us and we will assist you with your registration.
        </p>
      );
    } else {
      return (
        <Fragment>
          <p>We processed your booking request with the following results:</p>
          <List noHairlines noHairlinesMd form>
            <ListItem>
              {renderIcon(true)}
              <div className="invoice">
                Your invoice number is {invoiceNumber}.
                Please, mention it if you need to discuss your booking with us.
              </div>
            </ListItem>
            <ListItem>
              {renderIcon(emailSent)}
              {getEmailSentMessage()}
            </ListItem>
          </List>
        </Fragment>
      );
    }
  };

  return (
    <Page className='checkout checkout__confirmation' colorTheme={colorTheme} themeDark={themeDark}>
      <NavigationBar title="Confirmation" hasBackLink={false}/>
      <PageContent>
        <Block>
          <BlockTitle>Thank you for choosing us!</BlockTitle>
          {renderResults()}
          <Row className='buttons'>
            <Button raised round onClick={() => {
              dispatch(completeCheckout());
              f7router.clearPreviousHistory();
              f7router.navigate('/');
            }}>Home</Button>
          </Row>
        </Block>
      </PageContent>
    </Page>
  );
}