import React from "react";
import {useDispatch} from 'react-redux';
import {Icon, Link, ListItem} from "framework7-react";
import {deleteItemFromShoppingCart} from "../actions/shoppingCart";

export default ({item, index, app}) => {
  const {product: {name, price}, offer} = item;
  const dispatch = useDispatch();

  const deleteItem = () => {
    app.dialog.confirm('Are you sure?', 'Delete the Item', () => {
      dispatch(deleteItemFromShoppingCart(index));
    });
  };

  return (
    <ListItem className="shopping-cart-item" key={index} title={name} after={`$${price}`} subtitle={offer}>
      <Link slot="media" iconOnly onClick={deleteItem}>
        <Icon f7="trash"  />
      </Link>
    </ListItem>
  );
};