import {
  APPLY_COUPON_FAILURE,
  APPLY_COUPON_SUCCESS,
  BOOK_FAILURE,
  BOOK_SUCCESS,
  CALCULATE_DISCOUNTS_FAILURE,
  CALCULATE_DISCOUNTS_SUCCESS,
  CANCEL_CHECKOUT,
  CHECK_IF_EXISTING_CUSTOMER_FAILURE,
  CHECK_IF_EXISTING_CUSTOMER_SUCCESS,
  CLEAR_COUPON,
  CLEAR_ERROR,
  COMPLETE_CHECKOUT,
  SET_CONTACT_EMAIL,
  SET_CUSTOMER_INFO,
  SET_FORM_OF_PAYMENT,
  SET_STUDENT_NAME,
  START_CHECKOUT
} from "./types";
import axios from "axios";
import {pathOr, sum} from "ramda";

export const startCheckout = shoppingCart => ({
  type: START_CHECKOUT,
  shoppingCart
});

export const cancelCheckout = () => ({type: CANCEL_CHECKOUT});

export const completeCheckout = () => ({type: COMPLETE_CHECKOUT});

export const setContactEmail = (email, acceptedEula, storeEmailInSettings) => ({
  type: SET_CONTACT_EMAIL,
  email,
  acceptedEula,
  storeEmailInSettings
});

const checkIfExistingCustomerSucceeded = payload => ({
  type: CHECK_IF_EXISTING_CUSTOMER_SUCCESS,
  payload
});

const checkIfExistingCustomerFailed = error => ({
  type: CHECK_IF_EXISTING_CUSTOMER_FAILURE,
  error
});

export const checkIfExistingCustomer = (email, settings, items) => dispatch => {
  const {api: {baseUrl}} = settings;
  const ids = items.map(i => i.product.id);
  const url = `${baseUrl}/customer?email=${email}&validateOnly=true&items=${ids.join()}`;
  return axios.get(url)
    .then(payload => dispatch(checkIfExistingCustomerSucceeded(payload)))
    .catch(error => dispatch(checkIfExistingCustomerFailed(error)));
};

export const checkIfExistingCustomerAndCalculateDiscounts = (email, settings, items) => dispatch => {
  const {api: {baseUrl}} = settings;
  const ids = items.map(i => i.product.id);
  const url = `${baseUrl}/customer?email=${email}&validateOnly=true&items=${ids.join()}`;
  return axios.get(url)
    .then(payload => {
      dispatch(checkIfExistingCustomerSucceeded(payload));
      const calculateDiscountsPayload = {shoppingCart: items.map(({product: {id}, offer}) => ({id, offer}))};
      return axios.post(`${baseUrl}/discount`, calculateDiscountsPayload)
        .then(payload => dispatch(calculateDiscountsSuccess(payload)))
        .catch(error => dispatch(calculateDiscountsFailure(error)));
    })
    .catch(error => dispatch(checkIfExistingCustomerFailed(error)));
}

const calculateDiscountsSuccess = payload => ({
  type: CALCULATE_DISCOUNTS_SUCCESS,
  payload
});

const calculateDiscountsFailure = error => ({
  type: CALCULATE_DISCOUNTS_FAILURE,
  error
});

export const setFormOfPayment = formOfPayment => ({
  type: SET_FORM_OF_PAYMENT,
  formOfPayment
});

export const setCustomerInfo = (firstName, lastName, phone) => ({
  type: SET_CUSTOMER_INFO,
  firstName,
  lastName,
  phone
});

export const calculateDiscounts = (items, settings) => dispatch => {
  const {api: {baseUrl}} = settings;
  const payload = {shoppingCart: items.map(({product: {id}, offer}) => ({id, offer}))};
  return axios.post(`${baseUrl}/discount`, payload)
    .then(payload => dispatch(calculateDiscountsSuccess(payload)))
    .catch(error => dispatch(calculateDiscountsFailure(error)));
};

const applyCouponSuccess = payload => ({
  type: APPLY_COUPON_SUCCESS,
  payload
});

const applyCouponFailure = error => ({
  type: APPLY_COUPON_FAILURE,
  error
});

export const applyCoupon = (couponCode, items, settings) => dispatch => {
  const {api: {baseUrl}} = settings;
  const payload = {couponCode, shoppingCart: items.map(({product: {id}, offer}) => ({id, offer}))};
  return axios.post(`${baseUrl}/coupon`, payload)
    .then(payload => dispatch(applyCouponSuccess(payload)))
    .catch(error => dispatch(applyCouponFailure(error)));
};

export const clearCoupon = () => ({
  type: CLEAR_COUPON
});

export const clearError = () => ({
  type: CLEAR_ERROR
});

export const setStudentName = (index, sameAsUser, name) => ({
  type: SET_STUDENT_NAME,
  index,
  sameAsUser,
  name
});

const bookSuccess = payload => ({
  type: BOOK_SUCCESS,
  payload
});

const bookFailure = error => ({
  type: BOOK_FAILURE,
  error
});

export const book = (checkout, settings) => dispatch => {
  const {api: {baseUrl}} = settings;
  const {shoppingCart: {items}, customerInfo, email, formOfPayment, registrationFee} = checkout;
  const transformedItems = items.map(i => ({id: i.product.id, offer: i.offer, student: i.student}));
  const payload = {
    shoppingCart: {items: transformedItems},
    customerInfo, email, formOfPayment, requiresRegistration: registrationFee !== 0,
    couponCode: pathOr(undefined, ['coupon', 'code'], checkout),
    credit: pathOr(undefined, ['credit'], checkout)
  };
  return axios.post(`${baseUrl}/book`, payload)
    .then(payload => dispatch(bookSuccess(payload)))
    .catch(error => dispatch(bookFailure(error)));
};

export const getAppliedCredit = checkout => pathOr(false, ['credit', 'apply'], checkout) ?
  pathOr(0, ['credit', 'creditAmount'], checkout) : 0;

export const getTotalWithoutConvenienceFee = checkout => {
  const itemTotal = sum(pathOr([], ['shoppingCart', 'items'], checkout).map(item => {
    const {product: {price}} = item;
    return Number.parseFloat(price)
  }));
  const registrationFee = pathOr(0, ['registrationFee'], checkout);
  const couponTotal = pathOr(0, ['coupon', 'amount'], checkout);
  const discountTotal = sum(pathOr([], ['discounts'], checkout).map(i => i.amount));
  return itemTotal + registrationFee - couponTotal - discountTotal;
};

export const getConvenienceFee = (checkout, formOfPayment) => {
  const total = getTotalWithoutConvenienceFee(checkout);
  const credit = getAppliedCredit(checkout);
  const appliedFormOfPayment = formOfPayment ? formOfPayment : checkout.formOfPayment;
  const convenienceFee = appliedFormOfPayment === 'creditCard' ? 0.03 : 0;
  return (total - credit) * convenienceFee;
};

export const isForAdults = sku => ['C', 'Y', 'Z'].some(i => i === sku.substring(2, 3));

