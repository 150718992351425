import React, {Fragment, useEffect} from 'react';
import {has, pathOr} from "ramda";
import {useDispatch} from "react-redux";

export default ({app, action, cleanup, message, isEnabled}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEnabled) {
      app.dialog.preloader(message);
      dispatch(action()).finally(() => {
        app.dialog.close();
        if (cleanup) {
          cleanup();
        }
      });
    }
  });

  return (
    <Fragment/>
  );
}

export const shouldMakeApiCall = (app, checkout, attribute) => {
  const isCheckoutInProgress = checkout.inProgress && !pathOr(false, ['error'], checkout);
  const preloaderDialog = app.dialog.get('.dialog-preloader');
  const preloadIsOpen = pathOr(false, ['opened'], preloaderDialog);
  return isCheckoutInProgress && !has(attribute, checkout) && !preloadIsOpen;
};