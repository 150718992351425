import React from 'react';
import {Block, BlockTitle, Button, Page, PageContent} from 'framework7-react';

export default ({f7router}) => {
  return (
    <Page className="unexpected-refresh-page">
      <PageContent>
        <Block>
          <BlockTitle>You must have hit the refresh button!</BlockTitle>
          <p>This cleared your current state.</p>
          <Button raised round onClick={(f7router.navigate('/'))}>Home</Button>
        </Block>
      </PageContent>
    </Page>
  );
}

