import {INITIAL_LOAD_SUCCESS} from "../actions/types";
import {sortBy, prop} from "ramda";

export default (state={}, action) => {
  switch (action.type) {
    case INITIAL_LOAD_SUCCESS:
      return sortBy(prop('name'), action.payload.data.discounts);
    default:
      return state;
  }
};