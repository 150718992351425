import axios from 'axios';
import {INITIAL_LOAD_FAILURE, INITIAL_LOAD_SUCCESS} from "./types";

const initialLoadSuccess = payload => ({type: INITIAL_LOAD_SUCCESS, payload});
const initialLoadFailure = error => ({type: INITIAL_LOAD_FAILURE, error});

export const initialLoad = () => dispatch => {
  return axios.get(`/programs.json?timestamp=${new Date().getTime()}`)
    .then(payload => dispatch(initialLoadSuccess(payload)))
    .catch(error => dispatch(initialLoadFailure(error)));
};