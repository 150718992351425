import React, {useEffect, useState} from 'react';
import {Block, BlockTitle, Button, Icon, Link, Page, Popover, Row} from 'framework7-react';
import {initialLoad} from "../actions/initialLoad";
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import bannerImage from '../images/desktoplandingbanner.jpg';
import browseImage from '../images/landing_browse.jpg';
import galleryImage from '../images/landing_gallery.jpg';
import aboutImage from '../images/landing_about.jpg';
import SettingsIcon from "../components/SettingsIcon";
import Filters from "../components/Filters";
import {isDesktop} from "../utils";
import {clearCapacities} from "../actions/shopping";

export default ({f7router}) => {
  const colorTheme = useSelector(state => state.settings.colorTheme, shallowEqual);
  const themeDark = useSelector(state => state.settings.themeDark, shallowEqual);
  const programs = useSelector(state => state.programs, shallowEqual);
  const dispatch = useDispatch();
  const [filterOpen, setFilterOpen] = useState(false);
  const {app} = f7router;

  dispatch(clearCapacities());

  useEffect(() => {
    if (!(programs && programs.length)) {
      app.dialog.preloader('Loading ...');
      dispatch(initialLoad()).finally(() => app.dialog.close());
    }
  });

  return (
    <Page className='landing' colorTheme={colorTheme} themeDark={themeDark}>
      <Block className='banner'>
        <img src={bannerImage} alt="Banner"/>
      </Block>
      <Block className='icons'>
        <Link iconOnly href='/policies'>
          <Icon f7="document" tooltip="Policies"/>
        </Link>
        <Link iconOnly href={isDesktop(f7router) ? '/contact/desktop' : '/contact'}>
          <Icon f7="email" tooltip="Contact Us"/>
        </Link>
        <SettingsIcon/>
      </Block>
      <Link className='browse poster' onClick={() => setFilterOpen(true)}>
        <img src={browseImage} alt="Browse"/>
      </Link>
      <Link className="browse_menu menu" onClick={() => setFilterOpen(true)}>
        <Block>
          <Row className="menu__title">Browse</Row>
          <Row className="menu__details">
            <p>Classes</p>
            <p>Camps</p>
            <p>Workshops</p>
          </Row>
        </Block>
      </Link>
      <Link className='gallery poster' href="/gallery">
        <img src={galleryImage} alt="Gallery"/>
      </Link>
      <Link className="gallery_menu menu" href="/gallery">
        <Block>
          <Row className="menu__title">Gallery</Row>
          <Row className="menu__details">
            <p>Works</p>
            <p>Awards</p>
            <p>Portfolios</p>
          </Row>
        </Block>
      </Link>
      <Link className='about poster' href={isDesktop(f7router) ? '/about/desktop' : '/about'}>
        <img src={aboutImage} alt="About"/>
      </Link>
      <Link className="about_menu menu" href={isDesktop(f7router) ? '/about/desktop' : '/about'}>
        <Block>
          <Row className="menu__title">New to AWS?</Row>
          <Row className="menu__details">
            <p>Our Story</p>
            <p>Teachers</p>
            <p>Locations</p>
          </Row>
        </Block>
      </Link>
      <Popover className="filter" opened={filterOpen} onPopoverClosed={() => setFilterOpen(false)}>
        <Block>
          <BlockTitle>What Are You Interested In?</BlockTitle>
          <Filters showTypeFilter={true}/>
          <Row className='buttons'>
            <Button raised round onClick={() => setFilterOpen(false)}>Cancel</Button>
            <Button raised round onClick={() => {setFilterOpen(false);f7router.navigate('/browse');}}>Explore</Button>
          </Row>
        </Block>
      </Popover>
    </Page>
  );
}
