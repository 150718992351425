import React, {Fragment} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Block, BlockTitle, List, ListItem} from "framework7-react";
import {
  getAppliedCredit,
  getConvenienceFee,
  getTotalWithoutConvenienceFee,
  setFormOfPayment
} from "../../actions/checkout";
import CheckoutButtons from "./CheckoutButtons";
import shallowEqual from "react-redux/lib/utils/shallowEqual";


export default ({f7router, showButtons=true}) => {
  const checkout = useSelector(state => state.checkout, shallowEqual);
  const totalWithoutConvenienceFee = getTotalWithoutConvenienceFee(checkout);
  const creditCardConvenienceFee = getConvenienceFee(checkout, 'creditCard');
  const credit = getAppliedCredit(checkout);
  const creditNote = credit > 0 ? ` less $${Number.parseFloat(credit).toFixed(0)} of the credit` : '';
  const dispatch = useDispatch();


  const renderCheckoutButtons = () => {
      if (showButtons) {
        return (
          <CheckoutButtons f7router={f7router} canProceed={true} continueText="Review >" continueHandler={
            () => f7router.navigate('/checkout/book')
          }/>
        )
      } else {
        return (<Fragment/>);
      }
  };

  return (
    <Block>
      <BlockTitle>Please, choose the form of payment</BlockTitle>
      <p>Once you receive your invoice, how would you like to pay it?</p>
      <List mediaList noHairlines noHairlinesMd>
        <ListItem
          name="payment-options" radio title="Electronic Check (ACH)" subtitle="Free of charge"
          value="bank" defaultChecked={checkout.formOfPayment === 'bank'} after="$0.00"
          onChange={e => dispatch(setFormOfPayment(e.target.value))}
        />
        <ListItem
          name="payment-options" radio title="Credit Card" subtitle="3% convenience fee of your total"
          value="creditCard" after={`$${creditCardConvenienceFee.toFixed(2)}`}
          defaultChecked={checkout.formOfPayment === 'creditCard'}
          text={`We calculated the convenience fee based of your total of $${totalWithoutConvenienceFee}${creditNote}`}
          onChange={e => dispatch(setFormOfPayment(e.target.value))}
        />
      </List>
      {renderCheckoutButtons()}
    </Block>
  );
}