import {
  CLEAR_CAPACITIES,
  GET_CAPACITIES_SUCCESS,
  SET_SHOPPING_CATEGORY,
  SET_SHOPPING_LOCATION,
  SET_SHOPPING_PROGRAM,
  SET_SHOPPING_TYPE
} from "../actions/types";

export default (state = [], action) => {
  switch (action.type) {
    case GET_CAPACITIES_SUCCESS:
      return {...state, capacities: action.payload.data};
    case CLEAR_CAPACITIES:
      const {capacities, ...rest} = state;
      return rest;
    case SET_SHOPPING_PROGRAM:
      const {program, subtitle} = action;
      return {...state, program, subtitle};
    case SET_SHOPPING_CATEGORY:
      const {category} = action;
      return {...state, category};
    case SET_SHOPPING_LOCATION:
      const {location} = action;
      return {...state, location};
    case SET_SHOPPING_TYPE:
      const {name} = action;
      return {...state, type: name};
    default:
      return state;
  }
};