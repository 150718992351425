import React, {useState} from "react";
import {useDispatch} from 'react-redux';
import {ListInput, ListItem} from "framework7-react";
import {setStudentName, isForAdults} from '../../actions/checkout';

export default ({item, index}) => {
  const {product, offer, student} = item;
  const {sku} = product;
  const [name, setName] = useState(student.name);
  const showNameInput = sameAsUser => ! (isForAdults(sku) && sameAsUser) ? 'visible' : 'hidden';
  const checkboxClassName = isForAdults(sku) ? 'visible' : 'hidden';
  const [inputClassName, setInputClassName] = useState(showNameInput(student.sameAsUser));
  const dispatch = useDispatch();

  const checkboxChangeHandler = e => {
    setInputClassName(showNameInput(e.target.checked));
    if (e.target.checked) {
      setName('');
    }
    // noinspection JSValidateTypes
    dispatch(setStudentName(index, e.target.checked, name));
  };

  const textChangeHandler = e => {
    setName(e.target.value);
    // noinspection JSValidateTypes
    dispatch(setStudentName(index, inputClassName === 'hidden', e.target.value));
  };

  const textClearHandler = () => {
    setName('');
    // noinspection JSValidateTypes
    dispatch(setStudentName(index, false, ''));
  };

  return (
    <ListItem className="student-name" key={index} title={product.name} subtitle={offer}>
      <ul slot="footer">
        <ListItem
          title="I am the student" defaultChecked={isForAdults(sku)} className={checkboxClassName}
          key={index * 10} checkbox onChange={checkboxChangeHandler}/>
        <ListInput
          type="text" key={index * 10 + 1} label="Student's Name" floatingLabel
          value={name} placeholder="Student's Name" errorMessage="Student's Name is Required!"
          clearButton onInputClear={textClearHandler}
          required validate className={inputClassName} onChange={textChangeHandler}
        />
      </ul>
    </ListItem>
  );
};