import React, {Fragment} from 'react';
import {Block, BlockFooter, BlockTitle, List, ListItem, Page, PageContent} from 'framework7-react';
import {useDispatch, useSelector} from "react-redux";
import shallowEqual from "react-redux/es/utils/shallowEqual";
import NavigationBar from "../../components/NavigationBar";
import Discounts from "../../components/checkout/Discounts";
import {book, getAppliedCredit, getConvenienceFee, getTotalWithoutConvenienceFee} from "../../actions/checkout";
import CheckoutButtons from "../../components/checkout/CheckoutButtons";
import {pathOr} from "ramda";
import {handleReload} from "../../store";
import ReloadPage from "../ReloadPage";

export default ({f7router}) => {
  if (handleReload(useSelector(s => s, shallowEqual), f7router)) {return (<ReloadPage f7router={f7router}/>);}
  const colorTheme = useSelector(state => state.settings.colorTheme, shallowEqual);
  const themeDark = useSelector(state => state.settings.themeDark, shallowEqual);
  const checkout = useSelector(state => state.checkout, shallowEqual);
  const items = pathOr([], ['shoppingCart', 'items'], checkout);
  const settings = useSelector(state => state.settings, shallowEqual);
  const totalWithoutConvenienceFee = getTotalWithoutConvenienceFee(checkout);
  const convenienceFee = getConvenienceFee(checkout);
  const registrationFee = pathOr(0, ['registrationFee'], checkout);
  const appliedCredit = getAppliedCredit(checkout);
  const dispatch = useDispatch();
  const {app} = f7router;

  const renderItems = () => {
    return <List mediaList noHairlines noHairlinesMd>
      {items.map((item, index) => {
        const {product: {name, price}, offer, student} = item;
        return (
          <ListItem
            key={index} title={name} subtitle={`${offer}`}
            after={`$${Number.parseFloat(price).toFixed(2)}`}
            text={`Student's Name: ${student.sameAsUser ? 'N/A' : student.name}`
            }/>
        )
      })}
    </List>;
  };

  const renderCoupons = () => {
    const coupon = pathOr(undefined, ['coupon'], checkout);
    if (coupon) {
      const {code, amount} = coupon;
      const title = `Coupon ${code.toString().toUpperCase()}`;
      return (
        <Fragment>
          <BlockTitle>Coupons</BlockTitle>
          <List mediaList noHairlines noHairlinesMd>
            <ListItem key="1" title={title} after={`-$${Number.parseFloat(amount).toFixed(2)}`}/>
          </List>
        </Fragment>
      )
    } else {
      return (
        <Fragment/>
      );
    }
  };

  const renderDiscounts = () => {
    if (pathOr([], ['discounts'], checkout).length > 0) {
      return (
        <Fragment>
          <BlockTitle>Discounts</BlockTitle>
          <Discounts checkout={checkout} showNoDiscountsMessage={false}/>
        </Fragment>
      )
    } else {
      return (
        <Fragment/>
      );
    }
  };

  const renderRegistrationFee = () => {
    if (registrationFee) {
      return (
        <Fragment>
          <List mediaList noHairlines noHairlinesMd>
            <ListItem
              key="1" title="Registration Fee"
              after={`$${registrationFee.toFixed(2)}`}/>
          </List>
        </Fragment>
      );
    } else {
      return (
        <Fragment/>
      );
    }
  };

  const renderConvenienceFee = () => {
    if (convenienceFee) {
      return (
        <List mediaList noHairlines noHairlinesMd>
          <ListItem
            key="1" title="Credit Card Convenience Fee"
            subtitle={`3% of $${(totalWithoutConvenienceFee - appliedCredit).toFixed(2)}`}
            after={`$${convenienceFee.toFixed(2)}`}/>
        </List>
      );
    } else {
      return (
        <Fragment/>
      );
    }
  };

  const renderFeeHeader = () => {
    if (registrationFee || convenienceFee) {
      return (
        <BlockTitle>Fees</BlockTitle>
      )
    } else {
      return (
        <Fragment/>
      )
    }
  };

  const bookHandler = () => {
    app.dialog.preloader('Booking ...');
    // noinspection JSValidateTypes
    dispatch(book(checkout, settings))
      .then(() => f7router.navigate('/checkout/confirmation'))
      .finally(() => app.dialog.close());
  };

  const renderCreditNote = (total, credit) => {
    if (credit > 0) {
      return (
        <p>(<b>${total.toFixed(2)}</b> less the credit of <b>${credit.toFixed(2)}</b>)</p>
      );
    } else {
      return (
        <Fragment/>
      );
    }
  }

  const renderTotal = () => {
    const calculatedTotal = totalWithoutConvenienceFee + convenienceFee;
    const effectiveCredit = appliedCredit <= calculatedTotal ? appliedCredit : calculatedTotal;
    const effectiveTotal = calculatedTotal - effectiveCredit;
    return (
      <BlockFooter>
        <h3>Amount due: ${effectiveTotal.toFixed(2)}</h3>
        {renderCreditNote(calculatedTotal, effectiveCredit)}
      </BlockFooter>
    );
  }

  return (
    <Page className='checkout checkout__review_and_book' colorTheme={colorTheme} themeDark={themeDark}>
      <NavigationBar title="Review and Book" subtitle="Checkout: step 7 of 7" hasBackLink={true}/>
      <PageContent>
        <Block>
          <BlockTitle>Please, review your booking</BlockTitle>
          <BlockTitle>Shopping Cart</BlockTitle>
          {renderItems()}
          {renderCoupons()}
          {renderDiscounts()}
          {renderFeeHeader()}
          {renderRegistrationFee()}
          {renderConvenienceFee()}
          {renderTotal()}
          <CheckoutButtons f7router={f7router} canProceed={true} continueText="Book" continueHandler={bookHandler}/>
        </Block>
      </PageContent>
    </Page>
  );
}