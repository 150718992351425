import React from 'react';
import {Block, BlockFooter, List, ListItem, Page, PageContent} from 'framework7-react';
import {useSelector} from "react-redux";
import shallowEqual from "react-redux/es/utils/shallowEqual";
import NavigationBar from '../components/NavigationBar';
import {handleReload} from "../store";
import ReloadPage from "./ReloadPage";

export default ({f7router}) => {
  if (handleReload(useSelector(s => s, shallowEqual), f7router)) {return (<ReloadPage f7router={f7router}/>);}
  const colorTheme = useSelector(state => state.settings.colorTheme, shallowEqual);
  const themeDark = useSelector(state => state.settings.themeDark, shallowEqual);
  const discounts = useSelector(state => state.discounts, shallowEqual);

  return (
    <Page className="discount-page" colorTheme={colorTheme} themeDark={themeDark}>
      <NavigationBar title="Discounts" hasBackLink={true}/>
      <PageContent>
        <Block>
          <List mediaList noHairlines noHairlinesMd>
            {discounts.map((discount, index) => {
              const {name, description, price} = discount;
              const amount = price === 0 ? '' : `$${Math.abs(price)}`;
              return (
                <ListItem key={index} title={name} text={description} after={amount}/>
              )
            })}
          </List>
          <BlockFooter>
            Additional discounts may be available directly from the staff or through promotions
          </BlockFooter>
        </Block>
      </PageContent>
    </Page>
  );
};