import React from 'react';
import {Page, PageContent} from 'framework7-react';
import {useSelector} from "react-redux";
import shallowEqual from "react-redux/es/utils/shallowEqual";
import NavigationBar from "../../components/NavigationBar";
import ApiError from "../../components/checkout/ApiError";
import {handleReload} from "../../store";
import ReloadPage from "../ReloadPage";
import Coupons from "../../components/checkout/Coupons";

export default ({f7router}) => {
  if (handleReload(useSelector(s => s, shallowEqual), f7router)) {return (<ReloadPage f7router={f7router}/>);}
  const colorTheme = useSelector(state => state.settings.colorTheme, shallowEqual);
  const themeDark = useSelector(state => state.settings.themeDark, shallowEqual);

  return (
    <Page className='checkout checkout__coupons' colorTheme={colorTheme} themeDark={themeDark}>
      <NavigationBar title="Coupons" subtitle="Checkout: step 3 of 7" hasBackLink={true}/>
      <ApiError f7router={f7router}/>
      <PageContent>
        <Coupons f7router={f7router} showButtons={true} />
      </PageContent>
    </Page>
  );
}