import React from 'react';
import {Provider} from 'react-redux'
import 'framework7-icons';
import store from './store'
import {App, View} from 'framework7-react';
import HomePage from "./pages/HomePage";
import CatalogPage from "./pages/CatalogPage";
import BrowsePage from "./pages/BrowsePage";
import ProductPage from "./pages/ProductPage";
import ShoppingCartPage from "./pages/ShoppingCartPage";
import SettingsPage from "./pages/SettingsPage";
import EmailPage from "./pages/checkout/EmailPage";
import CustomerInfoPage from "./pages/checkout/CustomerInfoPage";
import DiscountsPage from "./pages/checkout/DiscountsPage";
import CouponsPage from "./pages/checkout/CouponsPage";
import StudentsPage from "./pages/checkout/StudentsPage";
import PaymentPreferencesPage from "./pages/checkout/PaymentPreferencesPage";
import ReviewAndBookPage from "./pages/checkout/ReviewAndBookPage";
import ConfirmationPage from "./pages/checkout/ConfirmationPage";
import ContactPage from './pages/ContactPage';
import DesktopContactPage from './pages/DesktopContactPage';
import GalleryPage from './pages/GalleryPage';
import AboutPage from './pages/AboutPage';
import PolicyPage from './pages/PolicyPage';
import DiscountPage from './pages/DiscountPage';
import DesktopCheckoutPage from "./pages/checkout/DesktopCheckoutPage";
import DesktopAboutPage from "./pages/DesktopAboutPage";
import InitialCatalogFilter from "./pages/InitialCatalogFilter";

const ArtWheelStudio = () => {
  const f7Params = {
    name: 'Art Wheel Studio',
    routes: [
      {
        path: '/',
        component: HomePage,
      },
      {
        path: '/catalog',
        component: CatalogPage
      },
      {
        path: '/browse',
        component: BrowsePage
      },
      {
        path: '/browse/pre-filter',
        component: InitialCatalogFilter
      },
      {
        path: '/product',
        component: ProductPage
      },
      {
        path: '/cart',
        component: ShoppingCartPage
      },
      {
        path: '/checkout/email',
        component: EmailPage
      },
      {
        path: '/checkout/customer',
        component: CustomerInfoPage
      },
      {
        path: '/checkout/desktop',
        component: DesktopCheckoutPage
      },
      {
        path: '/checkout/discounts',
        component: DiscountsPage
      },
      {
        path: '/checkout/coupons',
        component: CouponsPage
      },
      {
        path: '/checkout/students',
        component: StudentsPage
      },
      {
        path: '/checkout/payment',
        component: PaymentPreferencesPage
      },
      {
        path: '/checkout/book',
        component: ReviewAndBookPage
      },
      {
        path: '/checkout/confirmation',
        component: ConfirmationPage
      },
      {
        path: '/settings',
        component: SettingsPage
      },
      {
        path: '/contact',
        component: ContactPage
      },
      {
        path: '/contact/desktop',
        component: DesktopContactPage
      },
      {
        path: '/gallery',
        component: GalleryPage
      },
      {
        path: '/discounts',
        component: DiscountPage
      },
      {
        path: '/about',
        component: AboutPage
      },
      {
        path: '/policies',
        component: PolicyPage
      },
      {
        path: '/about/desktop',
        component: DesktopAboutPage
      }

    ]
  };

  return (
    <Provider store={store}>
      <App params={f7Params}>
        <View main animate={false} url='/'/>
      </App>
    </Provider>
  );
};

export default ArtWheelStudio;
