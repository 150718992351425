import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import reducer from '../reducers'

const initialState = {
  programs: [],
  discounts: [],
  shoppingContext: {
    location: 'RO',
    category: 'ABCXYZ'
  },
  shoppingCart: {
    items: []
  },
  checkout: {
    inProgress: false
  },
  settings: {
    colorTheme: 'green',
    themeDark: false,
    email: ''
  }
};

export default createStore(reducer(), initialState, compose(applyMiddleware(thunk)));

export const handleReload = (state, router) => {
  if (state === initialState) {
    router.clearPreviousHistory();
    return true;
  }
  return false;
};