import React from 'react';
import {Block, BlockTitle, List, Page, PageContent} from 'framework7-react';
import {useSelector} from "react-redux";
import shallowEqual from "react-redux/es/utils/shallowEqual";
import NavigationBar from "../../components/NavigationBar";
import CheckoutButtons from "../../components/checkout/CheckoutButtons";
import StudentName from '../../components/checkout/StudentName';
import {isForAdults} from "../../actions/checkout";
import {pathOr} from 'ramda';
import {handleReload} from "../../store";
import ReloadPage from "../ReloadPage";

export default ({f7router}) => {
  if (handleReload(useSelector(s => s, shallowEqual), f7router)) {return (<ReloadPage f7router={f7router}/>);}
  const colorTheme = useSelector(state => state.settings.colorTheme, shallowEqual);
  const themeDark = useSelector(state => state.settings.themeDark, shallowEqual);
  const checkout = useSelector(state => state.checkout, shallowEqual);
  const items = pathOr([], ['shoppingCart', 'items'], checkout);
  // noinspection JSUnresolvedVariable
  const canContinue = items.every(i => (isForAdults(i.product.sku) && i.student.sameAsUser) || i.student.name.length > 0);

  return (
    <Page className='checkout checkout__students' colorTheme={colorTheme} themeDark={themeDark}>
      <NavigationBar title="Students" subtitle="Checkout: step 5 of 7" hasBackLink={true}/>
      <PageContent>
        <Block>
          <BlockTitle>Please, provide students' names</BlockTitle>
          <p>Are you booking for your children? Please, provide student's names below</p>
          <List mediaList noHairlines noHairlinesMd noHairlinesBetween noHairlinesBetweenMd>
            {items.map((item, index) => <StudentName item={item} index={index}/>)}
          </List>
          <CheckoutButtons f7router={f7router} canProceed={canContinue} continueText="Payment >" continueHandler={
            () => f7router.navigate('/checkout/payment')
          }/>
        </Block>
      </PageContent>
    </Page>
  );
}