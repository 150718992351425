import {ADD_ITEM, DELETE_ITEM} from "./types";

export const addItemToShoppingCart = (product, offer) => ({
  type: ADD_ITEM,
  product,
  offer
});

export const deleteItemFromShoppingCart = (index) => ({
  type: DELETE_ITEM,
  index
});
