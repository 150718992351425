import React, {Fragment} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Badge, Icon, Link} from "framework7-react";

const renderNumberOfItems = itemCount => {
  if ( itemCount) {
    return (
      <Badge>{itemCount}</Badge>
    );
  } else {
    return (
      <Fragment/>
    );
  }
};

export default () => {
  const shoppingCart = useSelector(state => state.shoppingCart, shallowEqual);
  // noinspection HtmlUnknownTarget
  return (
    <Link iconOnly href='/cart/'>
      <Icon f7="shopping_cart">
        {renderNumberOfItems(shoppingCart.items.length)}
      </Icon>
    </Link>
  );
}