import React from 'react';
import {Fab, FabButtons, FabButton, Icon} from 'framework7-react';

export default ({f7router}) => {
  return (
    <Fab position='right-bottom' slot='fixed'>
      <Icon f7='more'/>
      <Icon f7='close'/>
      <FabButtons position="top">
        <FabButton label="About Us" fabClose={true} onClick={() => f7router.navigate('/about')}>
          <Icon f7='help'/>
        </FabButton>
        <FabButton label="Contact Us" fabClose={true} onClick={() => f7router.navigate('/contact')}>
          <Icon f7='email'/>
        </FabButton>
        <FabButton label="Discounts" fabClose={true} onClick={() => f7router.navigate('/discounts')}>
          <Icon f7='money_dollar' />
        </FabButton>
        <FabButton label="Gallery" fabClose={true} onClick={() => f7router.navigate('/gallery')}>
          <Icon f7='images'/>
        </FabButton>
      </FabButtons>
    </Fab>
  );
}