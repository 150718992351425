import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Block, Button, List, ListInput, Page, PageContent, Row} from 'framework7-react';
import FloatingMenuButton from '../components/FloatingMenuButton';
import shallowEqual from "react-redux/es/utils/shallowEqual";
import NavigationBar from "../components/NavigationBar";
import {applyAndSaveSettings} from "../actions/settings";
import {handleReload} from "../store";
import ReloadPage from "./ReloadPage";

export default ({f7router}) => {
  if (handleReload(useSelector(s => s, shallowEqual), f7router)) {return (<ReloadPage f7router={f7router}/>);}
  const settings = useSelector(state => state.settings, shallowEqual);
  const [email, setEmail] = useState(settings.email);
  const [colorTheme, setColorTheme] = useState(settings.colorTheme);

  const dispatch = useDispatch();

  const colorThemes = [
    'Red', 'Green', 'Blue', 'Pink', 'Yellow', 'Orange', 'Purple', 'Deep Purple',
    'Light Blue', 'Teal', 'Lime', 'Deep Orange', 'Gray'
  ];

  const handleClick = () => {
    dispatch(applyAndSaveSettings(email, colorTheme, false));
    f7router.back();
  };

  const renderOption = (option, index) => {
    const color = option.replace(' ', '').toLowerCase();
    return (<option key={index} value={color} color={color}>{option}</option>);
  };

  return (
    <Page className='settings' colorTheme={settings.colorTheme}>
      <NavigationBar title="Settings" hasBackLink={true}/>
      <PageContent>
        <Block>
          <List inlineLabels noHarilines noHairlinesMd noHairlinesBetween noHairlinesBetweenMd form>
            <ListInput
              type="email" value={email} placeholder="Your e-mail" label="E-mail"
              clearButton errorMessage="Please, enter a valid e-mail"
              validate onChange={e => setEmail(e.target.value)}
              onInputClear={() => {setEmail('')}}
            />
            <ListInput
              type="select" defaultValue={colorTheme} label="Theme"
              onChange={e => setColorTheme(e.target.value)}>
              {colorThemes.map((option, index) => renderOption(option, index))}
            </ListInput>
          </List>
          <Row className='buttons'>
              <Button raised round onClick={handleClick}>
                Apply
              </Button>
          </Row>
        </Block>
      </PageContent>
      <FloatingMenuButton f7router={f7router}/>
    </Page>
  );
}