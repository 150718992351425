import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from  'react';
import ReactDOM from  'react-dom';
import Framework7 from 'framework7/framework7.esm.bundle.js';
import Framework7React from 'framework7-react';
import ArtWheelStudio from './ArtWheelStudio';
import './index.css'

Framework7.use(Framework7React);

ReactDOM.render(React.createElement(ArtWheelStudio), document.getElementById('app'));