import React, {Fragment, useEffect} from 'react';
import {Block, Page, PageContent} from 'framework7-react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import NavigationBar from "../components/NavigationBar";
import ProductCard from "../components/ProductCard";
import {pathOr} from "ramda";
import {getCapacities, SEMESTER_CODES} from "../actions/shopping";
import {isDesktop} from "../utils";
import {handleReload} from "../store";
import ReloadPage from "./ReloadPage";

export default ({f7router}) => {
  if (handleReload(useSelector(s => s, shallowEqual), f7router)) {return (<ReloadPage f7router={f7router}/>);}
  const colorTheme = useSelector(state => state.settings.colorTheme, shallowEqual);
  const themeDark = useSelector(state => state.settings.themeDark, shallowEqual);
  const dispatch = useDispatch();
  const settings = useSelector(state => state.settings, shallowEqual);
  const capacities = useSelector(state => state.shoppingContext.capacities, shallowEqual);
  const programs = useSelector(state => state.programs, shallowEqual);
  const shoppingContext = useSelector(state => state.shoppingContext, shallowEqual);
  const location = pathOr('RO', ['location'], shoppingContext);
  const category = pathOr('ABCXYZ', ['category'], shoppingContext);
  const type = pathOr('All', ['type'], shoppingContext);
  const {app} = f7router;

  const reduceItem = (result, program) => {
    if (SEMESTER_CODES.indexOf(program.id) !== -1) {
      return program.programs.reduce((result, item) => reduceItem(result, item), [])
    }
    if (program.items || (program.programs && program.programs.length === 1)) {
      const programToSpread = program.programs ? program.programs[0] : program;
      if (type === 'All' || type === programToSpread.name) {
        return [...result, ...programToSpread.items];
      } else {
        return [...result];
      }
    } else {
      return program.programs.reduce((result, item) => reduceItem(result, item), [])
    }
  };

  const renderPrograms = () => {
    const items = programs.reduce((result, item) => reduceItem(result, item), []);
    const matchesLocationAndCategory = sku => sku.substring(0, 2) === location && category.includes(sku.substring(2, 3));
    const filteredItems = items.filter(i => matchesLocationAndCategory(pathOr('ROZ', ['sku'], i)));
    return (
      <Block>
        {
          filteredItems.map((item, index) => (
            <ProductCard
              key={index} product={item}
              capacities={capacities && capacities.hasOwnProperty(item.id) ? capacities[item.id] : {}}
              f7router={f7router}
            />))
        }
      </Block>
    )
  };

  const renderPageContent = () => {
    if (programs && programs.length) {
      return (renderPrograms())
    } else {
      return (<Fragment/>)
    }
  };

  useEffect(() => {
    if (!(shoppingContext.capacities)) {
      app.dialog.preloader('Loading ...');
      dispatch(getCapacities(settings)).finally(() => app.dialog.close());
    }
  });

  return (
    <Page className="browse" colorTheme={colorTheme} themeDark={themeDark}>
      <NavigationBar title="Our Catalog" hasBackLink={true} showFilters={isDesktop(f7router)}/>
      <PageContent>
        {renderPageContent()}
      </PageContent>
    </Page>
  );
}
