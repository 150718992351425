import React from 'react';
import {Block, Button, Col, Page, PageContent, Row} from "framework7-react";
import {shallowEqual, useSelector} from "react-redux";
import Filters from "../components/Filters";
import {handleReload} from "../store";
import ReloadPage from "./ReloadPage";

export default ({f7router}) => {
  if (handleReload(useSelector(s => s, shallowEqual), f7router)) {return (<ReloadPage f7router={f7router}/>);}
  const colorTheme = useSelector(state => state.settings.colorTheme, shallowEqual);
  const themeDark = useSelector(state => state.settings.themeDark, shallowEqual);

  return (
    <Page className="initial-browse-filter-page" colorTheme={colorTheme} themeDark={themeDark}>
      <PageContent>
        <Block>
          <Row className='component-title'><h2>What Are You Interested In?</h2></Row>
          <Row className='beige-well'>
            <p>Please, select location, age and type of classes you would like to see.
              Once on the catalog page, you can always change your filter through toolbar controls.
            </p>
          </Row>
          <Filters showTypeFilter={true}/>
          <Row className='well'>
            <Col width='35'>
              <Button raised round onClick={() => f7router.navigate('/')}>Home</Button>
            </Col>
            <Col width='35'>
              <Button raised round onClick={() => f7router.navigate('/browse')}>Explore</Button>
            </Col>
          </Row>
        </Block>
      </PageContent>
    </Page>
    );

  /*
          <Fragment>
          <NavigationBar title="Art Wheel Studio" hasBackLink={false} showFilters={isDesktop(f7router)}/>
          <PageContent>
            <Block>
              <BlockTitle>What Are You Interested In?</BlockTitle>
              <Filters/>
              <Row className='explore-button-row'>
                <Col width='35'>
                  <Button raised round onClick={() => f7router.navigate('/catalog')}>Explore</Button>
                </Col>
              </Row>
            </Block>
          </PageContent>
          <FloatingMenuButton f7router={f7router}/>
        </Fragment>

   */
}
