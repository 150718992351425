import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Block, BlockTitle, List, ListInput} from 'framework7-react';
import shallowEqual from "react-redux/es/utils/shallowEqual";
import CheckoutButtons from "../../components/checkout/CheckoutButtons";
import {setCustomerInfo} from "../../actions/checkout";
import {has, pathOr} from 'ramda';

export default ({f7router, showButtons=true, validityHandler= () => {}}) => {
  const checkout = useSelector(state => state.checkout, shallowEqual);
  const firstNameElement = useRef(null);
  const lastNameElement = useRef(null);
  const phoneElement = useRef(null);
  const [firstName, setFirstName] = useState(pathOr('', ['customerInfo', 'firstName'], checkout));
  const [hasValidFirstName, setHasValidFirstName] = useState(firstName.length);
  const [lastName, setLastName] = useState(pathOr('', ['customerInfo', 'lastName'], checkout));
  const [hasValidLastName, setHasValidLastName] = useState(lastName);
  const [phone, setPhone] = useState(pathOr('', ['customerInfo', 'phone'], checkout));
  const [hasValidPhone, setHasValidPhone] = useState(phone);
  const canProceed = hasValidFirstName && hasValidLastName && hasValidPhone;
  const creditAmount = pathOr(0, ['credit', 'creditAmount'], checkout);
  const dispatch = useDispatch();

  useEffect(() => {
    validityHandler(checkout.existingCustomer || canProceed);
    if (!showButtons && !checkout.existingCustomer && canProceed) {
      dispatch(setCustomerInfo(firstName, lastName, phone));
    }
  });

  const isValid = input => {
    return pathOr(false, ['current', '__reactRefs', 'inputEl', 'validity', 'valid'], input)
  };

  const renderRegistrationFeeMessage = () => {
    if (checkout.registrationFee === 0) {
      return (<span/>);
    } else {
      return (
        <p>
          Since it has been a while since your last booking,
          we will add a registration fee of ${checkout.registrationFee} to your total.
        </p>
      );
    }
  };

  const renderNewCustomerMessage = () => {
    if (checkout.registrationFee === 0) {
      return (
        <p>
          We could not find an existing account under <b>{checkout.email}</b>.
          Please, register by providing your information below.
        </p>
      );
    } else {
      return (
        <p>
          We could not find an existing account under <b>{checkout.email}</b>.
          Please, register by providing your information below.
          We will add a registration fee of ${checkout.registrationFee} to your total.
        </p>
      );
    }
  };

  const renderNextStepMessage = () => {
    if (showButtons) {
      return (<p>Please proceed to the next step.</p>);
    } else {
      return (<Fragment/>);
    }
  }

  const renderCheckoutButtons = (canProceed, continueHandler) => {
    if (showButtons) {
      return (
        <CheckoutButtons
          f7router={f7router} canProceed={canProceed} continueText="Coupons >"
          continueHandler={continueHandler}
        />
      );
    } else {
      return (
        <Fragment/>
      )
    }
  }

  const renderCreditAvailableMessage = () => {
    if ( creditAmount > 0) {
      return (
        <p>
          You have <b>{`$${Number.parseFloat(creditAmount).toFixed(2)}`}</b> of credit available in
          your account. We will apply it to your invoice.
        </p>
      );
    } else {
      return (
        <Fragment/>
      );
    }
  };

  if (has('existingCustomer', checkout)) {
    if (checkout.existingCustomer) {
      return (
        <Block>
          <BlockTitle>Welcome Back!</BlockTitle>
          <p>
            You already have an account under <b>{checkout.email}</b>.
            We will use it to generate and send you the invoice.
          </p>
          {renderCreditAvailableMessage()}
          {renderRegistrationFeeMessage()}
          {renderNextStepMessage()}
          {renderCheckoutButtons(true, () => f7router.navigate('/checkout/coupons'))}
        </Block>
      );
    } else {
      return (
        <Block>
          <BlockTitle>New Customer</BlockTitle>
          {renderNewCustomerMessage()}
          <List noHairlines noHairlinesMd form>
            <ListInput
              type="text" key="1" label="First Name" floatingLabel value={firstName} placeholder="First Name"
              errorMessage="First Name is Required!" clearButton required validate ref={firstNameElement}
              onChange={e => {setFirstName(e.target.value);setHasValidFirstName(isValid(firstNameElement))}}
              onInputClear={() => {setFirstName('');setHasValidFirstName(false)}}
            />
            <ListInput
              type="text" key="2" label="Last Name" floatingLabel value={lastName} placeholder="Last Name"
              errorMessage="Last Name is Required!" clearButton required validate ref={lastNameElement}
              onChange={e => {setLastName(e.target.value);setHasValidLastName(isValid(lastNameElement))}}
              onInputClear={() => {
                setLastName('');
                setHasValidLastName(false)
              }}
            />
            <ListInput
              type="tel" key="3" label="Phone" floatingLabel value={phone} placeholder="Phone"
              errorMessage="Please, use xxx-xxx-xxxx or xxxxxxxxxx format"
              required validate ref={phoneElement} clearButton
              pattern="([0-9]{3}-[0-9]{3}-[0-9]{4})|([0-9]{10})"
              onChange={e => {setPhone(e.target.value);setHasValidPhone(isValid(phoneElement))}}
              onInputClear={() => {setPhone('');setHasValidPhone(false)}}
            />
          </List>
          {renderCheckoutButtons(canProceed, () => {
            dispatch(setCustomerInfo(firstName, lastName, phone));
            f7router.navigate('/checkout/coupons');
          })}
        </Block>
      );
    }
  } else {
    return (<Fragment/>)
  }
}