import React from 'react';
import {Block, Link, Page, PageContent} from 'framework7-react';
import {useSelector} from "react-redux";
import shallowEqual from "react-redux/es/utils/shallowEqual";
import NavigationBar from '../components/NavigationBar';
import {handleReload} from "../store";
import ReloadPage from "./ReloadPage";

export default ({f7router}) => {
  if (handleReload(useSelector(s => s, shallowEqual), f7router)) {return (<ReloadPage f7router={f7router}/>);}
  const colorTheme = useSelector(state => state.settings.colorTheme, shallowEqual);
  const themeDark = useSelector(state => state.settings.themeDark, shallowEqual);

  return (
    <Page className="policies" colorTheme={colorTheme} themeDark={themeDark}>
      <NavigationBar title="Policies" hasBackLink={true}/>
      <PageContent>
        <Block className='our_policies_and_links'>
          <Block className="our_policies">
            <h2 className='our_policies__header'>Policies</h2>
            <div className='our_policies__text'>
              <p>
                Before registering and arriving at your AWS class, please familiarize yourself
                with our methods and policies. Though we try to be lenient and succinct with rules,
                our goal is to provide a safe environment for kids and students to study art. We will enforce
                those rules when absolutely necessary. The current set of rules is always available on this page,
                and you are welcome to contact an AWS admin for questions or to discuss/request GTC changes.
              </p>
            </div>
          </Block>
          <Block className="policy_links">
            <h2 className='policy_links__header'>Documents and Links</h2>
            <div className='policy_links__text'>
              <Link href="terms-and-conditions-2019.pdf" external={true} target="_blank">Terms and Conditions</Link>
              <Link href="privacy-policy-2019.pdf" external={true} target="_blank">Privacy Policy</Link>
            </div>
          </Block>
        </Block>
      </PageContent>
    </Page>
  );
};
