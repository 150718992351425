import React from 'react';
import {Page, PageContent} from 'framework7-react';
import {useSelector} from "react-redux";
import shallowEqual from "react-redux/es/utils/shallowEqual";
import ReloadPage from "./ReloadPage";
import FloatingMenuButton from '../components/FloatingMenuButton';
import NavigationBar from "../components/NavigationBar";
import ProductCard from "../components/ProductCard";
import {handleReload} from "../store";

export default ({f7router}) => {
  if (handleReload(useSelector(s => s, shallowEqual), f7router)) {return (<ReloadPage f7router={f7router}/>);}
  const colorTheme = useSelector(state => state.settings.colorTheme, shallowEqual);
  const themeDark = useSelector(state => state.settings.themeDark, shallowEqual);
  const shoppingContext = useSelector(state => state.shoppingContext, shallowEqual);
  const {program: {name, items}, subtitle} = shoppingContext;

  return (
    <Page className='product-page' colorTheme={colorTheme} themeDark={themeDark}>
      <NavigationBar title={name} subtitle={subtitle} hasBackLink={true}/>
      <PageContent>
        {items.map((item, index) => (<ProductCard key={index} product={item} f7router={f7router} />))}
      </PageContent>
      <FloatingMenuButton f7router={f7router}/>
    </Page>
  );
}

